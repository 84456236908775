import progress from '../../../assets/images/progress.png';
import progressBg from '../../../assets/images/progress_bg.png';

import sky_0 from '../../../assets/images/bg/sky_0.png';
import sky_1 from '../../../assets/images/bg/sky_1.png';
import sky_2 from '../../../assets/images/bg/sky_2.png';
import water from '../../../assets/images/bg/water.png';
import bg_fighter0 from '../../../assets/images/bg/bg_fighter0.png';
import bg_fighter1 from '../../../assets/images/bg/bg_fighter1.png';
import bg_ship0 from '../../../assets/images/bg/bg_ship0.png';
import bg_ship1 from '../../../assets/images/bg/bg_ship1.png';
import cloud from '../../../assets/images/bg/cloud.png';
import dusk_mountain0 from '../../../assets/images/bg/dusk_mountain0.png';
import dusk_mountain1 from '../../../assets/images/bg/dusk_mountain1.png';
import dusk_mountain2 from '../../../assets/images/bg/dusk_mountain2.png';
import icy_mountain0 from '../../../assets/images/bg/icy_mountain0.png';
import icy_mountain1 from '../../../assets/images/bg/icy_mountain1.png';
import icy_mountain2 from '../../../assets/images/bg/icy_mountain2.png';
import sand_mountains from '../../../assets/images/bg/sand_mountains.png';
import ship_bow from '../../../assets/images/bg/ship_bow.png';
import ship_stern from '../../../assets/images/bg/ship_stern.png';
import jackpotBG from '../../../assets/images/jackpot/bg.png';
import jackpotBGNotWin from '../../../assets/images/jackpot/not_win.png';

import beer from '../../../assets/images/smiles/beer.png';
import fire from '../../../assets/images/smiles/fire.png';
import flushed from '../../../assets/images/smiles/flushed.png';
import folded_hands from '../../../assets/images/smiles/folded_hands.png';
import four_leaf_clover from '../../../assets/images/smiles/four_leaf_clover.png';
import money_mouth from '../../../assets/images/smiles/money_mouth.png';
import pile_of_poo from '../../../assets/images/smiles/pile_of_poo.png';
import screaming_in_fear from '../../../assets/images/smiles/screaming_in_fear.png';
import slot_machine from '../../../assets/images/smiles/slot_machine.png';
import sunglasses from '../../../assets/images/smiles/sunglasses.png';
import swearing from '../../../assets/images/smiles/swearing.png';
import tongue from '../../../assets/images/smiles/tongue.png';

import openSmiles from '../../../assets/images/smiles/button_open.png';
import closeSmiles from '../../../assets/images/smiles/button_close.png';

import eImageType from './eImageType';
import * as PIXI from 'pixi.js';
import eSmileType from './eSmileType';

export default new class ImagePreloader {
  constructor() {
    this.imagesData = [
      { type: eImageType.EIT_PROGRESS, src: progress },
      { type: eImageType.EIT_PROGRESS_BG, src: progressBg },

      { type: eImageType.EIT_SKY_0, src: sky_0 },
      { type: eImageType.EIT_SKY_1, src: sky_1 },
      { type: eImageType.EIT_SKY_2, src: sky_2 },
      { type: eImageType.EIT_WATER, src: water },
      { type: eImageType.EIT_BG_FIGHTER_0, src: bg_fighter0 },
      { type: eImageType.EIT_BG_FIGHTER_1, src: bg_fighter1 },
      { type: eImageType.EIT_BG_SHIP_0, src: bg_ship0 },
      { type: eImageType.EIT_BG_SHIP_1, src: bg_ship1 },
      { type: eImageType.EIT_CLOUD, src: cloud },
      { type: eImageType.EIT_DUSK_MOUNTAIN_0, src: dusk_mountain0 },
      { type: eImageType.EIT_DUSK_MOUNTAIN_1, src: dusk_mountain1 },
      { type: eImageType.EIT_DUSK_MOUNTAIN_2, src: dusk_mountain2 },
      { type: eImageType.EIT_ICY_MOUNTAIN_0, src: icy_mountain0 },
      { type: eImageType.EIT_ICY_MOUNTAIN_1, src: icy_mountain1 },
      { type: eImageType.EIT_ICY_MOUNTAIN_2, src: icy_mountain2 },
      { type: eImageType.EIT_SAND_MOUNTAIN_2, src: sand_mountains },
      { type: eImageType.EIT_SHIP_BOW, src: ship_bow },
      { type: eImageType.EIT_SHIP_STERN, src: ship_stern },
      { type: eImageType.EIT_JACKPOT_BG, src: jackpotBG },
      { type: eImageType.EIT_JACKPOT_BG_NOT_WIN, src: jackpotBGNotWin },

      { type: eSmileType.EST_BEER, src: beer },
      { type: eSmileType.EST_FIRE, src: fire },
      { type: eSmileType.EST_FLUSHED, src: flushed },
      { type: eSmileType.EST_FOLDED_HANDS, src: folded_hands },
      { type: eSmileType.EST_FOUR_LEAF_CLOVER, src: four_leaf_clover },
      { type: eSmileType.EST_MONEY_MOUTH, src: money_mouth },
      { type: eSmileType.EST_PILE_OF_POO, src: pile_of_poo },
      { type: eSmileType.EST_SCREAMING_IN_FEAR, src: screaming_in_fear },
      { type: eSmileType.EST_SLOT_MACHINE, src: slot_machine },
      { type: eSmileType.EST_SUNGLASSES, src: sunglasses },
      { type: eSmileType.EST_SWEARING, src: swearing },
      { type: eSmileType.EST_TONGUE, src: tongue },

      { type: eImageType.EIT_OPEN_SMILES, src: openSmiles },
      { type: eImageType.EIT_CLOSE_SMILES, src: closeSmiles },
    ];
  }

  startLoading(app, onComplete) {
    this.onLoadingComplete = onComplete;
    for (let item of this.imagesData) {
      PIXI.Loader.shared.add(item.type, item.src);
    }
    PIXI.Loader.shared.onComplete.add(this.onAnimationLoaded.bind(this));
    PIXI.Loader.shared.onError.add(this.onError.bind(this));
  }

  onError(e) {
    console.error(e);
    this.hasError = true;
  }

  onAnimationLoaded(_, resources) {
    if (this.hasError) return;
    this.resources = resources;
    this.onLoadingComplete();
  };

  createImage(type) {
    return new PIXI.Sprite(this.getTexture(type));
  }

  getTexture(type) {
    return this.resources[type].texture;
  }

  getSrc(type) {
    const index = this.imagesData.findIndex((item) => item.type === type);
    if(index === -1){
      console.error(`Unlisted image type: ${type}`)
    }
    return this.imagesData[index].src;
  }
}
