import '../sass/styles.scss';
import * as PIXI from 'pixi.js';
import 'pixi-spine';

import ControllerBG from './bg/ControllerBG';
import AnimationLoader from './preloaders/animations/AnimationLoader';
import ControllerUI from './ui/ControllerUI';
import { requestAuth } from './api/wsRequests';
import ImagePreloader from './preloaders/images/ImagePreloader';
import OPWrapperService from './libs/op-wrapper-service';
import SoundsPreloader from './preloaders/sounds/SoundsPreloader';
import ManagerSFX from './ManagerSFX';
import SoundManager from './soundManager/SoundManager';
import ManagerResult from './ManagerResult';
import JetController from './jet/JetController';
import './utils/array';
import eSoundsType from './preloaders/sounds/eSoundsType';
import ArcadelaraManager from './platforms/arcadelara/arcadelaraManager'
import MessageAdapter from './messageAdapter/MessageAdapter';
import GameScaleManager from './GameScaleManager';

import wrapperConfig from './wrapperConfig';

const Spine = PIXI.spine.Spine;

new OPWrapperService(wrapperConfig);
window.OPWrapperService.Fullscreen.showButtonIfAndroid();

Spine.prototype.autoUpdateTransform = function () {
  if (Spine.globalAutoUpdate) {
    this.lastTime = this.lastTime || Date.now();
    var timeDelta = (Date.now() - this.lastTime) * 0.001;
    this.lastTime = Date.now();
    this.update(timeDelta);
  } else {
    this.lastTime = 0;
  }

  try {
    PIXI.Container.prototype.updateTransform.call(this);
  } catch (e) {
    // console.warn(e.message);
  }
};

PIXI.Container.prototype.updateTransform = function () {
  if (this.sortableChildren && this.sortDirty) {
    this.sortChildren();
  }
  this._boundsID++;
  this.transform.updateTransform(this.parent.transform);
  // TODO: check render flags, how to process stuff here
  this.worldAlpha = this.alpha * this.parent.worldAlpha;
  for (var i = 0, j = this.children.length; i < j; ++i) {
    var child = this.children[i];
    try {
      if (child.visible) {
        child.updateTransform();
      }
    } catch (e) {
      // console.warn(e.message);
    }
  }
};

window.testSounds = () => {
  // SoundManager.play(eSoundsType.EST_SUPERSONIC_START, 1, false, 'default', () => {
  //   SoundManager.play(eSoundsType.EST_SUPERSONIC_IDLE, 1, true);
  // })
  // setTimeout(() => SoundManager.play(eSoundsType.EST_SUPERSONIC_IDLE, 1, true), 1000);
  SoundManager.play(eSoundsType.EST_TEST, 1, true);
};
export default class Game {
  constructor() {
    this.app = null;
    this.compiled = false;

    this.configLoaded = false;
    this.fontsLoaded = false;
    this.assetsLoaded = false;
    this.soundsLoaded = false;
    this.animationsLoaded = false;
    this.gameInitilized = false;

    const multiplier = 1;
    this.config = {
      width: 1015 * multiplier,
      height: 650 * multiplier,
    };
    PIXI.settings.FAIL_IF_MAJOR_PERFORMANCE_CAVEAT = false;

    this.start();

    MessageAdapter.init();
    this._blockContextMenu();
  }

  start() {
    this.applicationOptions = {
      // antialias: true,
      // legacy: true,
      // backgroundColor: 0x6fc1f6,
      // transparent: true,
      width: this.config.width,
      height: this.config.height,
      resolution: this.isRetinaDisplay() ? 1 : 1,
      autoResize: true
    };
    this.app = new PIXI.Application(this.applicationOptions);
    this.container = document.getElementById('canvasContainer');
    this.container.appendChild(this.app.view);
    this.app.view.style.setProperty('height', '100%');
    this.app.view.style.setProperty('width', '');

    this.miniPreloader = document.getElementById('miniPreloader');
    GameScaleManager.init(this.app);

    this.loadAssets();
    this.loadFonts();
    this.loadAnimations();
    this.loadSounds();

    PIXI.Loader.shared.add('GoldFont', 'assets/fonts/bitmap/goldFont.xml', 'font');

    PIXI.Loader.shared.onProgress.add((data) => {
      OPPreloader.setProgress(data.progress);
      MessageAdapter.loadProgress(data.progress);
    });

    requestAuth()
      .then(() => {
        console.log('Config loaded');
        new ControllerUI();
        this.configLoaded = true;
        this.tryInitGame();
      });
    PIXI.Loader.shared.load();
  }

  loadAssets() {
    ImagePreloader.startLoading(this.app, () => {
      console.log('Assets loaded');
      this.assetsLoaded = true;
      this.tryInitGame();
    });
  }

  loadFonts() {
    // WebFont.load({
    //   custom: {
    //     families: ['lobster', 'SegoeUI'],
    //   },
    //   fontinactive: console.error,
    //   active: () => {
    //     console.log('Fonts loaded');
    //     this.fontsLoaded = true;
    //     this.tryInitGame();
    //   }
    // })
  }

  loadAnimations() {
    AnimationLoader.startLoading(this.app, () => {
      console.log('Animations loaded');
      this.animationsLoaded = true;
      this.tryInitGame();
    });
  }

  loadSounds() {
    SoundsPreloader.startLoading();
  }

  tryInitGame() {
    if (this._readyForInit()) {
      this.initGame();
    }
  }

  get ready() {
    return this._readyForInit() && this.gameInitilized;
  }

  _readyForInit() {
    return (
      // this.fontsLoaded &&
      (
        this.assetsLoaded &&
        this.animationsLoaded ||
        window.OPWrapperService.config.lightMode
      ) &&
      this.configLoaded
      // this.soundsLoaded &&
    );
  }

  initGame() {
    const gameWrapper = document.querySelector('.wrapper');
    gameWrapper.style.display = 'block';
    OPPreloader.hide();
    window.OPWrapperService.freeBetsController.show();
    MessageAdapter.loadCompleted();
    SoundManager.init();
    ManagerResult.init();
    this.gameInitilized = true;
    this.miniPreloader.style.setProperty('display', 'none');

    if (!window.OPWrapperService.config.lightMode) {
      const container = new PIXI.Container();
      // container.scale.set(0.245);
      // container.scale.set(0.1);
      container.pivot.set(3500, 1900);
      container.x = 860;
      container.y = 400;

      this.mainContainer = container;
      this.app.stage.addChild(this.mainContainer);
      window.controllerBG = new ControllerBG(this.mainContainer);

      ManagerSFX.init();
      new JetController(this.mainContainer);

      // window.OPPreloader.hide();

      // const test = AnimationLoader.createAnimation(eAnimationType.EAT_TEST);
      // test.state.setAnimation(0, 'animation', true);
      // test.x = 3130;
      // test.y = 1800;
      // this.mainContainer.addChild(test);
    } else {
      this.app.stop();
    }
  }

  //todo: move to another place
  isRetinaDisplay() {
    let result = false;
    if (window.matchMedia) {
      let mq = window.matchMedia('only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)');
      result = (mq && mq.matches || (window.devicePixelRatio > 1));
    }
    return result;
  }
  _blockContextMenu() {
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
      e.stopPropagation();
    });
  }
}

window.addEventListener('load', () => new Game());

// OPPreloader.hide();
// const gameWrapper = document.querySelector('.wrapper');
// gameWrapper.style.display = 'block';
// GameScaleManager.init();

