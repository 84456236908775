import en from '../assets/i18n/en';

export default {
  bundle: 'fighter',
  localizationBundle: 'fighter',
  clock: { enabled: true },
  localizations: { en },
  lang: 'en',
  fallbackLang: 'en'
};
