import StartFreeBetsPopup from './items/startFreeBetsPopup';
import EndFreeBetsPopup from './items/endFreeBetsPopup';
import FreeBetsBottomBar from './items/freeBetsBottomBar';
import eFreeBetsStatusType from './items/eFreeBetsStatusType';
import eStatusType from './items/eFreeBetsStatusType';

export default class FreeBetsController {
  constructor(container) {
    this.container = container;
    this.data = {
      bet: 0,
      freeBets: 0,
      freeBetsQuantity: 0,
      currency: '',
      totalWin: 0,
      status: eFreeBetsStatusType.EFBST_NO_DATA
    };
    this.eFreeBetsStatusType = eFreeBetsStatusType;

  }

  set freeBets(value) {
    this._freeBets = value;
    if (this.bottomBar) this.bottomBar.setFreeBets(value)
  }

  get freeBets() {
    return this._freeBets;
  }

  get isFirstFreeBet() {
    return this._isFirstFreeBet;
  }

  get isLastFreeBet() {
    return this._isLastFreeBet;
  }

  get isFreeBets() {
    return this.isFirstFreeBet || this.isActive || this.isLastFreeBet;
  }

  get isActive() {
    return this._isActive;
  }

  setData(data) {
    if (!data && this.data.status === eFreeBetsStatusType.EFBST_NO_DATA) {
      this._isLastFreeBet = false;
      return;
    } else if (!data) {
      this.data.status = eFreeBetsStatusType.EFBST_NO_DATA;
      this._hide();
    } else if (data.status === eFreeBetsStatusType.EFBST_EXPIRED) {
      window.OPWrapperService.showMessage('freebets expired','',true);
      this._hide();
      this.data.status = eFreeBetsStatusType.EFBST_NO_DATA;
    }

    const newData = {
      bet: data && data.bet_levels[data.bet_level] || 0,
      freeBets: data && (data.freebets_quantity - data.freebets_used) || 0,
      freeBetsQuantity: data && data.freebets_quantity || 0,
      currency: data && data.currency || 0,
      decimals: data && data.decimals || 0,
      totalWin: data && data.total_win || 0,
      status: data && data.status || ''
    }
    this.data = { ...this.data, ...newData };
    if (!this.data.freeBets) this._isActive = false;
    this._isFirstFreeBet = !this.enabled && this.data.status === eFreeBetsStatusType.EFBST_ACTIVE;
    this._isLastFreeBet = this.data.status === eFreeBetsStatusType.EFBST_PLAYED || this.data.status === eFreeBetsStatusType.EFBST_NO_DATA;
    this.updateFreeBets();
  }

  show() {
    if (this.enabled || this.data.status !== eFreeBetsStatusType.EFBST_ACTIVE) return;
    this.enabled = true;
    this._isActive = true;
    if (!this.view) this._init();
    this.startFreeSpinPopup.show();
    this.bottomBar.show();
    this._startUpdate();
  }

  decreaseFreeBets() {
    if (this.enabled) this.freeBets--;
  }

  update() {
    this.updateFreeBets();
    this.updateTotalWin();
  }

  updateFreeBets() {
    this.freeBets = this.data.freeBets;
  }

  updateTotalWin() {
    if (!this.enabled) return;
    this.totalWin = this.data.totalWin || 0
    this.bottomBar.setTotalWin(this.totalWin);
    if (this.data.status === eFreeBetsStatusType.EFBST_PLAYED) {
      this.endFreeSpinPopup.show(this.data);
    }
  }

  _init() {
    this.container.insertAdjacentHTML('beforeend', `<div id="freeBets"></div>`);
    this.view = document.getElementById('freeBets');

    this.startFreeSpinPopup = new StartFreeBetsPopup(this.view);
    this.endFreeSpinPopup = new EndFreeBetsPopup(this.view);
    this.bottomBar = new FreeBetsBottomBar(this.view);

    this.endFreeSpinPopup.onClose = this._hide.bind(this);
  }

  _hide() {
    if (this.bottomBar) this.bottomBar.hideElement();
    this.enabled = false;
  }

  _startUpdate() {
    this.totalWin = 0;
    this.startFreeSpinPopup.update(this.data);
    this.bottomBar.update(this.data);
    this.updateFreeBets();
  }

}
