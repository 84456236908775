import GlobalDispatcher from './events/GlobalDispatcher';
import eWSEventId from './api/eWSEventId';
import { AsyncWithCancel, sleep } from './utils/promise';
import StatModel from './models/StatModel';
import GameModel from './models/GameModel';
import eGameState from './models/eGameState';
import eEventType from './events/eEventType';

export default new class ManagerResult {
  constructor() {
    this.controllerBG = null;
    this.controllerCounter = null;
    this.controllerScore = null;
    this.controllerJet = null;
    this.needUpdate = false;

    this.createFinishAnimation = function* (data) {
      let delay = 2000;
      const isJackpot = data.params.reason === 'jackpot';
      if (isJackpot) {
        if (data.params.awards.length === 0) {
          delay = 13000;
        } else {
          delay = 30000;
        }
      }
      yield sleep(delay);
      StatModel.loadBetsFromCache();
      if (data.params.awards.length > 0 && !isJackpot) {
        yield this.controllerScore.show(data);
      }
      yield this.controllerBG.animateBlocker(1);
      yield this.controllerBG.resetPositions();
      yield this.controllerBG.animateBlocker(0);
      this.controllerBG.animateStartWaiting();
    };
  }

  init() {
    document.addEventListener('visibilitychange', this._onVisibilityChange.bind(this));
    GlobalDispatcher.add(eWSEventId.EWEI_START, this._onStart, this);
    GlobalDispatcher.add(eWSEventId.EWEI_PROGRESS, this._onProgress, this);
    GlobalDispatcher.add(eWSEventId.EWEI_FINISH, this._onFinish, this);
  }

  _onVisibilityChange() {
    console.warn('visibilitychange');
    if (document.hidden) {
      // window.miniPreloader.show();
    } else {
      this.needUpdate = !window.OPWrapperService.config.lightMode;
      if (GameModel.state === eGameState.EGS_RESULTS) {
        if (this.animation) this.animation.cancel();
        if (!window.OPWrapperService.config.lightMode) {
          this.controllerBG.resetPositions();
          this.controllerBG.resetWaitingBets();
          this.controllerJet.resetWaitingBets();
        }
        this.controllerCounter.reset();
      }
      this.controllerScore.hide();
    }
  }

  _onStart() {
    if (this.animation)
      this.animation.cancel();

    if (this.needUpdate)
      window.miniPreloader.hide();

    this.needUpdate = false;
  }

  _onProgress(data) {
    if (this.needUpdate)
      window.miniPreloader.hide();

    if (this.needUpdate && GameModel.state === eGameState.EGS_PROGRESS) {
      this.controllerBG.setX(data.params.x);
      this.controllerJet.resetProgress();
      this.needUpdate = false;
    }
  }

  _onFinish(data) {
    if (window.OPWrapperService.config.lightMode) {
      GlobalDispatcher.dispatch(eEventType.EET_RESET);
    } else {
      if (!this.controllerBG || document.hidden) return;
      this.animation = new AsyncWithCancel(this.createFinishAnimation(data));
      this.animation.call();
    }
  }
};
