import LocalizationManager from '../../i18n/LocalizationManager';
import { tournamentPopupTypes } from '../controller/popupTypes';

export default class ScoreTournamentPopup {
  constructor(container, config, showRulesCallback, hideCallback) {

    this.container = container;
    this.title = config.tournamentTitle;
    this.endTime = config.endTime.split('-');
    this.prize = config.prizeValue;
    this.player = config.player;
    this.topPlacesCount = config.topPlacesCount;
    this.playersResults = config.playersResults;
    this.maxRowsDisplayed = 7;
    this.showBottomBlock = this.player.position > this.maxRowsDisplayed;
    this._createMarkup();

    this.view = document.getElementById('tournament_score_popup');
    this.titlePseudoEl = document.getElementById('tournament_score_title_pseudo');
    this.titleEl = document.getElementById('tournament_score_title');
    this.rulesButtonEl = document.getElementById('tournament_score_rules_button_inner');
    this.prizeFundEl = document.getElementById('tournament_score_prize_fund');
    this.endsInEl = document.getElementById('tournament_score_ends_in');
    this.timeLeftEl = document.getElementById('tournament_score_time_left');
    this.tableHeadPositionEl = document.getElementById('tournament_score_table_head_position');
    this.tableHeadPlayerEl = document.getElementById('tournament_score_table_head_player');
    this.tableHeadScoreEl = document.getElementById('tournament_score_table_head_score');
    this.tableHeadMyPositionEl = document.getElementById('tournament_score_table_head_my_position');
    this.tableHeadMyNicknameEl = document.getElementById('tournament_score_table_head_my_nickname');
    this.tableHeadMyScoreEl = document.getElementById('tournament_score_table_head_my_score');

    LocalizationManager.addLocalizationChangedCallback(this._updateLocalization.bind(this));
    this._updateLocalization();

    document.getElementById('tournament_score_close_button').addEventListener('click', () => hideCallback(tournamentPopupTypes.SCORE));
    document.getElementById('tournament_score_rules_button').addEventListener('click', showRulesCallback);
  }

  show() {
    this.view.classList.remove('tournament_hidden');
    this.container.classList.remove('tournament_hidden');
  };

  hide() {
    this.view.classList.add('tournament_hidden');
    this.container.classList.add('tournament_hidden');
  };

  _updateLocalization() {
    const titleLocalization = LocalizationManager.getLocalizedText('OPWrapperService.tournaments.common.title', [this.title]);
    const prizeFundLocalization = LocalizationManager.getLocalizedText('OPWrapperService.tournaments.common.prizeFund');
    const timeLeftLocalization = LocalizationManager.getLocalizedText(
      'OPWrapperService.tournaments.common.endTime', [this.endTime[0], this.endTime[1], this.endTime[2], this.endTime[3]]);
    const scoreLocalization = LocalizationManager.getLocalizedText('OPWrapperService.tournaments.score.score');

    this.titlePseudoEl.setAttribute('title', titleLocalization);
    this.titleEl.innerText = titleLocalization;
    this.rulesButtonEl.innerText = LocalizationManager.getLocalizedText('OPWrapperService.tournaments.common.rules');
    this.prizeFundEl.setAttribute('title', prizeFundLocalization);
    this.prizeFundEl.innerText = prizeFundLocalization;
    this.endsInEl.innerText = LocalizationManager.getLocalizedText('OPWrapperService.tournaments.common.endsIn');
    this.timeLeftEl.setAttribute('title', timeLeftLocalization);
    this.timeLeftEl.innerText = timeLeftLocalization;
    this.tableHeadPositionEl.innerText = LocalizationManager.getLocalizedText('OPWrapperService.tournaments.score.position');
    this.tableHeadPlayerEl.innerText = LocalizationManager.getLocalizedText('OPWrapperService.tournaments.score.player');
    this.tableHeadScoreEl.innerText = scoreLocalization;
    this.tableHeadMyPositionEl.innerText = LocalizationManager.getLocalizedText('OPWrapperService.tournaments.score.myPosition');
    this.tableHeadMyNicknameEl.innerText = LocalizationManager.getLocalizedText('OPWrapperService.tournaments.score.nickname');
    this.tableHeadMyScoreEl.innerText = scoreLocalization;
  }

  _createMarkup() {
    this.container.insertAdjacentHTML('beforeend',
      `<div id="tournament_score_popup" class="tournamentPopup tournament_hidden">` +
      `<div class="tournamentTitle">` +
      `<span id="tournament_score_title_pseudo" class="tournament_score_title" title="Турнир: “${this.title}”">` +
      `<span id="tournament_score_title">Турнир: “${this.title}”</span>` +
      `</span>` +
      `<div id="tournament_score_close_button" class="tournament_buttonClose"></div>` +
      `</div>` +

      `<div class="tournament_popup_body">` +
      `<div class="tournament_head">` +
      `<div class="tournament_head_top_line"></div>` +
      `<div id="tournament_score_rules_button" class="tournament_button_rules">` +
      `<span id="tournament_score_rules_button_inner">ПРАВИЛА</span>` +
      `</div>` +

      `<div class="tournament_message">` +
      `<span class="tournament_prize_title" title="ПРИЗОВОЙ ФОНД" id="tournament_score_prize_fund">ПРИЗОВОЙ ФОНД</span>` +
      `<span class="tournament_prize_value" title="${this.prize}">` +
      `<span>${this.prize}</span>` +
      `</span>` +
      `<span class="tournament_end" id="tournament_score_ends_in">заканчивается через</span>` +
      `<span class="tournament_time_left" id="tournament_score_time_left"
    title="${this.endTime[0]}дн ${this.endTime[1]}ч ${this.endTime[2]}мин ${this.endTime[3]}с">
    ${this.endTime[0]}дн ${this.endTime[1]}ч ${this.endTime[2]}мин ${this.endTime[3]}с
    </span>` +
      `</div>` +
      `<div class="tournament_head_bottom_line"></div>` +
      `</div>` +

      `<div class="tournament_popup_table_head">` +
      `<span id="tournament_score_table_head_position">Место</span>` +
      `<span id="tournament_score_table_head_player">Игрок</span>` +
      `<span></span>` +
      `<span id="tournament_score_table_head_score">Баллы</span>` +
      `</div>` +

      `<div class="${this.player.position > this.maxRowsDisplayed ?
        'tournament_popup_table' : 'tournament_popup_table_bigger'}">
      
      ${this.playersResults.map(player =>
        `<div class="${player.position <= this.topPlacesCount ?
          'tournament_popup_table_row_win' : 'tournament_popup_table_row'}">
          <span class="tournament_star ${player.position > this.topPlacesCount ? 'tournament_star_hidden' : ''}"></span>
          <div class="tournament_popup_table_row_inner">` +
        `<span>${player.position}</span>` +
        `<span>${player.name}</span>` +
        `<span></span>` +
        `<span>${player.score}</span>` +
        `</div>` +
        `</div>`
      ).join('')}

        </div>` +
      `</div>` +

      `<div class="tournament_popup_bottom_bg ${!this.showBottomBlock ? 'tournament_popup_bottom_bg_hidden' : ''}">` +
      `<div class="tournament_popup_table_bottom">` +
      `<div class="tournament_popup_table_head">` +
      `<span id="tournament_score_table_head_my_position">Ваше место</span>` +
      `<span id="tournament_score_table_head_my_nickname">Ник</span>` +
      `<span></span>` +
      `<span id="tournament_score_table_head_my_score">Баллы</span>` +
      `</div>` +
      `<div class="tournament_popup_table_row">` +
      `<div class="tournament_popup_table_row_inner">` +
      `<span>${this.player.position}</span>` +
      `<span>${this.player.name}</span>` +
      `<span></span>` +
      `<span>${this.player.score}</span>` +
      `</div>` +
      `</div>` +
      `</div>` +
      `</div>` +
      `</div>`
    );
  }
}
