import eAnimationType from './eAnimationType';
import * as PIXI from 'pixi.js';

class AnimationsLoader {
  constructor() {
    this.animationsData = [
      { type: eAnimationType.EAT_FIGHTER, src: 'assets/animations/fighter/fighter.json' },
      { type: eAnimationType.EAT_SHOOTER, src: 'assets/animations/shooter/shooters_animations.json' },
      { type: eAnimationType.EAT_PARACHUTES, src: 'assets/animations/parachutes/parachutes.json' },
      { type: eAnimationType.EAT_BG_JETS, src: 'assets/animations/bg_jets/skeleton.json' },
    ];
  }

  startLoading(app, onComplete) {
    this.onLoadingComplete = onComplete;
    this.app = app;
    for (let item of this.animationsData) {
      PIXI.Loader.shared.add(item.type, item.src);
    }
    PIXI.Loader.shared.onComplete.add(this.onAnimationLoaded.bind(this));
    PIXI.Loader.shared.onError.add(this.onError.bind(this));
  }

  onError(e) {
    console.error(e);
    this.hasError = true;
  }

  onAnimationLoaded(_, resources) {
    if(this.hasError) return;
    this.resources = resources;
    this.onLoadingComplete();
  };

  createAnimation(type) {
    if (!window.OPWrapperService.UserAgentDetector.isOpera) {
      this.resources[type].spineAtlas.pages.forEach(page => {
        page.baseTexture.alphaMode = PIXI.ALPHA_MODES.PMA;
      });
    }
    return new PIXI.spine.Spine(this.resources[type].spineData);
  }
}

export default new AnimationsLoader();
