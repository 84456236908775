export default {
  EWEI_AUTH: 'auth',
  EWEI_START: 'start',
  EWEI_PROGRESS: 'progress',
  EWEI_FINISH: 'finish',
  EWEI_BET: 'bet',
  EWEI_TAKE: 'take',
  EWEI_CANCEL: 'cancel',
  EWEI_SPAWN_CRATE: 'spawnCrate',
  EWEI_DESTROY_CRATE: 'destroyCrate',
  EWEI_BALANCE: 'balance',
  EWEI_ERROR: 'error',
  EWEI_EMOJI: 'emoji',
  EWEI_PING: 'ping',
  EWEI_JACKPOT_AMOUNT: 'jackpotAmount',
  EWEI_RECONNECT: 'reconnect'
};
