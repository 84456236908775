import AnimationLoader from '../preloaders/animations/AnimationLoader';
import eAnimationType from '../preloaders/animations/eAnimationType';
import eJetAnimation from './eJetAnimation';
import GlobalDispatcher from '../events/GlobalDispatcher';
import eWSEventId from '../api/eWSEventId';
import eEventType from '../events/eEventType';
import GameModel from '../models/GameModel';
import eGameState from '../models/eGameState';
import ManagerResult from '../ManagerResult';
import SoundManager from '../soundManager/SoundManager';
import eSoundsType from '../preloaders/sounds/eSoundsType';
import ImagePreloader from '../preloaders/images/ImagePreloader';
import eImageType from '../preloaders/images/eImageType';
import TweenMax from 'gsap';
import StatModel from '../models/StatModel';
import ControllerWinLabel from '../ui/ControllerWinLabel';
import Constants from '../Constants';

export default class JetController {
  constructor(container) {
    this.animation = AnimationLoader.createAnimation(eAnimationType.EAT_FIGHTER);
    const animation_1 = this.animation.state.setAnimation(0, eJetAnimation.EJA_JET_START_IDLE, false);
    animation_1.timeScale = 0;
    this.animation.x = 3130;
    this.animation.y = 1877;
    this.supersonickOn = false;
    this.idle2On = false;
    this.refuelingInProgress = false;
    this.soundGroup = 'fighter';
    this.soundVolume = 1;

    this.jackpotBg = ImagePreloader.createImage(eImageType.EIT_JACKPOT_BG);
    this.jackpotBg.x = 2640;
    this.jackpotBg.y = 1500;
    this.jackpotBg.alpha = 0;

    this.jackpotBgNotWin = ImagePreloader.createImage(eImageType.EIT_JACKPOT_BG_NOT_WIN);
    this.jackpotBgNotWin.x = 2640;
    this.jackpotBgNotWin.y = 1500;
    this.jackpotBgNotWin.alpha = 0;

    this.controllerWinLabel = new ControllerWinLabel()

    const jetContainer = container.getChildByName('jet_container', true);
    jetContainer.addChild(this.jackpotBg, this.jackpotBgNotWin, this.animation, this.controllerWinLabel.label, this.controllerWinLabel.tryAgain);

    for (let item of this.animation.children) {
      if (item.children[0]) {
        if (item.children[0].attachment.name === 'body') {
          window.jetBodyContainer = item.children[0]; //TODO: this is bad solution
          break;
        }
      }
    }

    if (GameModel.state === eGameState.EGS_PROGRESS) {
      this.resetProgress();
    } else {
      this.animation.state.setAnimation(0, eJetAnimation.EJA_JET_START_IDLE, true);
    }

    this.animation.state.addListener({
      complete: (data) => {
        if (data.animation.name === eJetAnimation.EJA_JET_START) {
          GlobalDispatcher.dispatch(eEventType.EET_ANIMATE_DEFLECTOR_ON);
        }

        if (data.animation.name.includes('refueling_succeed')) {
          this.refuelingInProgress = false;
        }

        if (data.animation.name === eJetAnimation.EJA_JET_IDLE_SUPERSONIC_TO_IDLE) {
          this.supersonickOn = false;
        }

        if ([eJetAnimation.EJA_JACKPOT_NOT_WIN, eJetAnimation.EJA_BOSS_REWARD_NOT_WIN, eJetAnimation.EJA_JACKPOT_MISSED, eJetAnimation.EJA_BOSS_REWARD_MISSED].includes(data.animation.name)) {
          GlobalDispatcher.dispatch(eEventType.EET_JACKPOT_ENDED);
        }
      }
    });

    GlobalDispatcher.add(eWSEventId.EWEI_START, this.start, this);
    GlobalDispatcher.add(eWSEventId.EWEI_PROGRESS, this.progress, this);
    GlobalDispatcher.add(eWSEventId.EWEI_FINISH, this.finish, this);
    GlobalDispatcher.add(eEventType.EET_RESET, this.reset, this);
    GlobalDispatcher.add(eEventType.EET_ANIMATE_START_WAITING, this.animateStartWaiting, this);

    GlobalDispatcher.add(eWSEventId.EWEI_SPAWN_CRATE, this.spawnCrate, this);
    GlobalDispatcher.add(eWSEventId.EWEI_DESTROY_CRATE, this.destroyCrate, this);

    ManagerResult.controllerJet = this;
  }

  start() {
    this.animation.state.setAnimation(0, eJetAnimation.EJA_JET_START_RUN, false);
    this.animation.state.addAnimation(0, eJetAnimation.EJA_JET_IDLE, true, 0);
    SoundManager.play(eSoundsType.EST_JET_START_FORSAGE, this.soundVolume * 0.5, false, this.soundGroup, () => {
      SoundManager.play(eSoundsType.EST_JET_IDLE, this.soundVolume, true, this.soundGroup);
    })
  }

  progress(data) {
    if (this.refuelingInProgress) return;
    if (data.params.coef > 15 && !this.supersonickOn) {
      this.animation.state.setAnimation(0, eJetAnimation.EJA_JET_IDLE_SUPERSONIC_START, false);
      this.animation.state.addAnimation(0, eJetAnimation.EJA_JET_IDLE_SUPERSONIC_IDLE, true, 0);
      this.supersonickOn = true;
      SoundManager.play(eSoundsType.EST_JET_SUPERSONIC_START, this.soundVolume * 0.5, false, this.soundGroup, () => {
        SoundManager.play(eSoundsType.EST_JET_SUPERSONIC_IDLE, this.soundVolume, true, this.soundGroup);
      });
    } else if (data.params.coef > 5 && data.params.coef < 15 && !this.idle2On) {
      this.animation.state.setAnimation(0, eJetAnimation.EJA_JET_IDLE2, true);
      this.idle2On = true;
      SoundManager.play(eSoundsType.EST_JET_IDLE2_START, this.soundVolume, false, this.soundGroup, () => {
        SoundManager.play(eSoundsType.EST_JET_IDLE2, this.soundVolume, true, this.soundGroup);
      });
    }
  }

  animateStartWaiting() {
    this.animation.state.setAnimation(0, eJetAnimation.EJA_JET_START, false);
    this.animation.state.addAnimation(0, eJetAnimation.EJA_JET_START_IDLE, true, 0);
    SoundManager.play(eSoundsType.EST_JET_START, this.soundVolume, false, this.soundGroup, () => {
      SoundManager.play(eSoundsType.EST_JET_WAITING_IDLE, this.soundVolume, true, this.soundGroup);
    });
  }

  finish(data) {
    const reason = data.params.reason;
    this.animation.state.clearTracks();
    if (reason === 'jackpot') {
      this._playJackpotAnimation(data)
    } else if (reason === 'mine') {
      SoundManager.stopGroup(this.soundGroup);
      SoundManager.play(eSoundsType.EST_JET_DESTROYED, this.soundVolume, false, this.soundGroup);
      this.animation.state.setAnimation(0, eJetAnimation.EJA_JET_DESTROYED, false);
    } else {
      this.animation.skeleton.setToSetupPose();
      this.animation.state.setAnimation(0, eJetAnimation.EJA_JET_REFUELING_FAIL, false);
    }
  }

  spawnCrate() {
    if (this.supersonickOn) {
      this.animation.state.setAnimation(0, eJetAnimation.EJA_JET_IDLE_SUPERSONIC_TO_IDLE, false);
      this.animation.state.addAnimation(0, eJetAnimation.EJA_JET_CONNECTING_UNI, false, 0);
    } else {
      this.animation.state.setAnimation(0, eJetAnimation.EJA_JET_CONNECTING_UNI, false);
    }
    this.animation.state.addAnimation(0, eJetAnimation.EJA_JET_REFUELING_IDLE, true, 0);
    this.refuelingInProgress = true;

    SoundManager.stopGroup(this.soundGroup);
    SoundManager.play(eSoundsType.EST_CONNECTING_STRATOTANKER, this.soundVolume * 0.5, false, this.soundGroup);
  }

  destroyCrate(data) {
    const key = `EJA_JET_REFUELING_SUCCEED_${Math.round(data.params.multi % 1 * 10)}0`;
    // console.log(key);
    const animationName = data.params.cont === 0 ?
      eJetAnimation.EJA_JET_REFUELING_FAIL :
      eJetAnimation[key];
    this.animation.state.setAnimation(0, animationName, false);
    this.animation.state.addAnimation(0, eJetAnimation.EJA_JET_IDLE, true, 0);

    const soundName = data.params.cont === 0 ?
      eSoundsType.EST_JET_REFUELING_FAIL : eSoundsType.EST_JET_REFUELING_SUCCEED;
    SoundManager.stopGroup(this.soundGroup);
    SoundManager.play(soundName, this.soundVolume, false, this.soundGroup);
  }

  resetWaitingBets() {
    this.reset();
    this.animation.state.setAnimation(0, eJetAnimation.EJA_JET_START_IDLE, true);
  }

  resetProgress() {
    this.reset();
    this.supersonickOn = false;
    this.idle2On = false;
    this.refuelingInProgress = GameModel.crate != null;
    const animation = this.refuelingInProgress ? eJetAnimation.EJA_JET_REFUELING_IDLE : eJetAnimation.EJA_JET_IDLE;
    this.animation.state.setAnimation(0, animation, true);
  }

  reset() {
    this.animation.skeleton.setToSetupPose();
    const animation_1 = this.animation.state.setAnimation(0, eJetAnimation.EJA_JET_START, false);
    animation_1.timeScale = 0;
    this.supersonickOn = false;
    this.idle2On = false;
    this.refuelingInProgress = false;

    TweenMax.killTweensOf(this.jackpotBg);
    TweenMax.killTweensOf(this.jackpotBgNotWin);
    this.jackpotBg.alpha = 0;
    this.jackpotBgNotWin.alpha = 0;
    this.controllerWinLabel.hide();

    SoundManager.stop(eSoundsType.EST_JACKPOT_START, this.soundGroup);
    SoundManager.stop(eSoundsType.EST_JACKPOT_MUSIC, this.soundGroup);
    SoundManager.stop(eSoundsType.EST_JACKPOT_MISSED, this.soundGroup);

    clearTimeout(this.timeoutShowTryAgain);
  }

  _playJackpotSound() {
    SoundManager.stopGroup(this.soundGroup);
    SoundManager.play(eSoundsType.EST_JACKPOT_START, this.soundVolume * 0.5, false, this.soundGroup, () => {
      SoundManager.stopGroup(this.soundGroup);
      SoundManager.play(eSoundsType.EST_JACKPOT_MUSIC, this.soundVolume, false, this.soundGroup);
    })
  }

  _playJackpotMissedSound() {
    SoundManager.stopGroup(this.soundGroup);
    SoundManager.play(eSoundsType.EST_JACKPOT_MISSED, this.soundVolume * 0.5, false, this.soundGroup);
  }

  _playJackpotAnimation(data) {
    this.animation.skeleton.setToSetupPose();
    const awards = data.params.awards;
    const sign = window.OPWrapperService.config.jackpotEnabled ? 'JACKPOT' : 'BOSS_REWARD';
    let animation = '';
    let animationIdle = '';
    let bg = null;
    if (awards.length === 0) {
      console.log('MISSED')
      animation = eJetAnimation[`EJA_${sign}_MISSED`];
    } else {
      console.log('NOT_WIN')
      animation = eJetAnimation[`EJA_${sign}_NOT_WIN`];
      animationIdle = eJetAnimation[`EJA_${sign}_NOT_WIN_IDLE`];
      bg = this.jackpotBgNotWin;
      this.timeoutShowTryAgain = setTimeout(() => this.controllerWinLabel.tryAgain.visible = true, Constants.BEFORE_JACKPOT_SIGN_TIMEOUT);
    }

    if (this.supersonickOn) {
      this.animation.state.setAnimation(0, eJetAnimation.EJA_JET_IDLE_SUPERSONIC_TO_IDLE, false);
      this.animation.state.addAnimation(0, animation, false, 0);
      if (bg) TweenMax.to(bg, 2, { alpha: 1 });
      if (animationIdle)
        this.animation.state.addAnimation(0, animationIdle, true, 0);
      SoundManager.play(eSoundsType.EST_JET_SUPERSONIC_TO_IDLE, this.soundVolume, false, this.soundGroup, () => {
        awards.length === 0 ? this._playJackpotMissedSound() : this._playJackpotSound();
      })
    } else {
      this.animation.state.setAnimation(0, animation, false);
      if (bg) TweenMax.to(bg, 2, { alpha: 1 });
      if (animationIdle)
        this.animation.state.addAnimation(0, animationIdle, true, 0);
      awards.length === 0 ? this._playJackpotMissedSound() : this._playJackpotSound();
    }
  }

  _calculateWinAmount(awards) {
    const myBets = [];
    for (const item of awards) {
      if (StatModel.myBetsInfo.findIndex((bet) => bet.betId === item.betId) !== -1) myBets.push(item);
    }

    let winAmount = 0;
    for (const myBet of myBets) {
      winAmount += myBet.jackpot_total * myBet.jackpot_percent;
    }

    return winAmount;
  }
}
