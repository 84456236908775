import GlobalDispatcher from '../events/GlobalDispatcher';
import eWSEventId from '../api/eWSEventId';
import eEventType from '../events/eEventType';
import GameModel from '../models/GameModel';
import eGameState from '../models/eGameState';
import ManagerResult from '../ManagerResult';
import { Elastic } from 'gsap';

export default class ControllerCounter {
  constructor() {
    this.label = document.getElementById('counter');
    this.tween = null;
    this.alreadyGrowValue = [];

    if (GameModel.state === eGameState.EGS_RESULTS) {
      this.reset();
    }

    GlobalDispatcher.add(eWSEventId.EWEI_PROGRESS, this.onProgress, this);
    GlobalDispatcher.add(eWSEventId.EWEI_FINISH, this.onFinish, this);
    GlobalDispatcher.add(eEventType.EET_RESET, this.reset, this);
    GlobalDispatcher.add(eWSEventId.EWEI_START, () => this.setText(1));

    GlobalDispatcher.add(eWSEventId.EWEI_DESTROY_CRATE, this.refuelingAnimation, this);

    ManagerResult.controllerCounter = this;
  }

  onFinish(data) {
    if (data.params.reason === 'jackpot') {
      this.label.innerText = '';
    } else {
      this.setText(data.params.coef);
      if (this.tween) this.tween.kill();
      this.tween = null;
    }
  }

  refuelingAnimation(data) {
    if (this.tween) this.tween.kill();
    this.tween = null;
    const temp = { value: data.params.coef };
    this.tween = TweenMax.to(temp, 0.2, {
      value: data.params.coef * data.params.multi,
      onUpdate: () => {
        this.label.innerText = '×' + this._round(temp.value);
      }
    })
  }

  onProgress(data) {
    this.setText(data.params.coef)
  }

  setText(value) {
    this.label.innerText = '×' + this._round(value);

    if (this.tween) return;

    const fontSize = 4 + Math.min(3, 0.2 * Math.floor(value));
    const temp = { value: 0 };
    this.tween = TweenMax.to(temp, 0.3, {
      value: 1,
      onUpdate: () => {
        this.label.style.setProperty('font-size', `${fontSize + temp.value}rem`);
      },
      onComplete: () => {
        this.tween = null;
      },
      ease: Elastic.out,
      repeat: -1,
      yoyo: true,
    })

    // if (!this.tween && this.alreadyGrowValue !== Math.floor(value)) {
    //   this.alreadyGrowValue = Math.floor(value);
    //   const temp = { value: 0 };
    //   const multiplier = Math.min(2, 0.2 *  this.alreadyGrowValue);
    //   this.tween = TweenMax.to(temp, 1.1, {
    //     value: 1,
    //     onUpdate: () => {
    //       this.label.style.setProperty('font-size', `${4 + multiplier * temp.value}rem`);
    //     },
    //     onComplete: () => {
    //       this.tween = null;
    //     },
    //     ease: Elastic.easeOut.config(2, 0.5),
    //   })
    // }
  }

  reset() {
    if (this.tween) this.tween.kill();
    this.tween = null;
    this.label.innerText = window.OPWrapperService.localizations.getLocalizedText('make_your_bets');
    this.label.style.setProperty('font-size', '4rem');
  }

  _round(value) {
    let result = Math.round(value * 100) / 100;
    if (!/\.\d{2}$/.test(result)) {
      const decimals = result.toString().match(/\.\d$/);
      result += decimals ? '0' : '.00';
    }
    return result;
  }
}
