import LocalizationManager from '../../i18n/LocalizationManager';

export default class RulesTournamentPopup {
  constructor(container, rules, goBackCallback) {

    this.container = container;
    this.rules = rules;
    this._createMarkup();

    this.titleEl = document.getElementById('tournament_rules_title');
    this.rulesElArray = document.querySelectorAll('[id=tournament_rules_list_el');
    this.view = document.getElementById('tournament_rules_popup');

    LocalizationManager.addLocalizationChangedCallback(this._updateLocalization.bind(this));
    this._updateLocalization();

    document.getElementById('rules_tournament_close_button').addEventListener('click', goBackCallback);
    document.getElementById('rules_tournament_back_button').addEventListener('click', goBackCallback);
  }

  show() {
    this.view.classList.remove('tournament_hidden');
    this.container.classList.remove('tournament_hidden');
  };

  hide() {
    this.view.classList.add('tournament_hidden');
    this.container.classList.add('tournament_hidden');
  };

  _updateLocalization() {
    const titleLocalization = LocalizationManager.getLocalizedText('OPWrapperService.tournaments.common.rules');

    this.titleEl.setAttribute('title', titleLocalization);
    this.titleEl.innerText = titleLocalization;
    this.rulesElArray.forEach((el, i) => {
      el.innerText = '';
      el.insertAdjacentHTML('beforeend', LocalizationManager.getLocalizedText(this.rules[i]))
    });
  }

  _createMarkup() {
    this.container.insertAdjacentHTML('beforeend',
      `<div id="tournament_rules_popup" class="tournament_rules_popup tournament_hidden">` +
      `<div id="rules_tournament_close_button" class="tournament_rules_close_button"></div>` +
      `<div id="rules_tournament_back_button" class="tournament_rules_back_button"></div>` +

      `<div class="tournament_rules_popup_body">` +
      `<span id="tournament_rules_title" class="tournament_rules_popup_title" title="Правила">
      ПРАВИЛА
      </span>` +

      `<div class="tournament_rules_popup_body_scroll">` +

      `${this.rules.map((rule, i) =>
        `<div class="tournament_rules_popup_rule_block">
        <span class="tournament_rules_popup_rule_number">
        <span title="${i + 1}">
        <span>${i + 1} </span>
        </span>
        </span>
        <div class="tournament_head_top_line"></div>
        <div id="tournament_rules_list_el" class="tournament_rules_popup_rule_text"></div>
        </div>`
      ).join('')}` +

      `</div>
      </div>
      </div>`
    );
  };

}
