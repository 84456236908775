import GameModel from '../models/GameModel';

export default class ControllerJackpot {
  constructor() {
    this.element = document.getElementById('jackpotView');
    GameModel.onJackpotChanged.add(this._updateView.bind(this));
    this._updateView();
  }

  _updateView() {
    const name = OPWrapperService.config.jackpotEnabled ?
      window.OPWrapperService.localizations.getLocalizedText('jackpot') : window.OPWrapperService.localizations.getLocalizedText('boss_reward');
    this.element.innerText = `${name}: ${GameModel.formatJackpot}`;
  }
}
