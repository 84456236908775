import BaseFreeBetsPopup from './baseFreeBetsPopup';
import LocalizationManager from '../../i18n/LocalizationManager';

export default class StartFreeBetsPopup extends BaseFreeBetsPopup {
  constructor(container) {
    super(container);
    this.freeBetsElement = this.view.querySelector('.freeBetsPopup__freeBets')
    this.betElement = this.view.querySelector('.freeBetsPopup__bet')
  }

  update(data) {
    this.data = data;
    this.freeBetsElement.textContent = this.data.freeBets;
    this.betElement.textContent = this.data.bet;
  }

  createMarkup() {
    return `
    <div id="startFreeBetsPopup" class="blocker">
      <div class="freeBetsContent">
        <div class="freeBets__title">${LocalizationManager.getLocalizedText(this.defaultLocalizationPath + '.HavePopup.title')}</div>
        <div class="freeBetsPopup__amountBlock">
          <div class="freeBetsPopup__amount freeBetsPopup__freeBets">20</div>
          <div class="freeBetsPopup__desc">${LocalizationManager.getLocalizedText(this.defaultLocalizationPath + '.HavePopup.freeBets')}</div>
        </div>
        <div class="freeBetsPopup__desc_small">${LocalizationManager.getLocalizedText(this.defaultLocalizationPath + '.HavePopup.with')}</div>
        <div class="freeBetsPopup__amountBlock">
          <div class="freeBetsPopup__amount freeBetsPopup__bet">5</div>
          <div class="freeBetsPopup__desc">${LocalizationManager.getLocalizedText(this.defaultLocalizationPath + '.HavePopup.bet')}</div>
        </div>
        <button class="freeBets__button">
          <div class="freeBets__buttonText">${LocalizationManager.getLocalizedText(this.defaultLocalizationPath + '.ok')}</div>
        </button>
      </div>
    </div>
    `
  }
}
