import UserAgentDetector from './libs/op-wrapper-service/utils/UserAgentDetector';
import Event from './utils/event';

export default new class GameScaleManager {
  init(app) {
    this.app = app;
    this._resizeTimeouts = [];
    this._defaultResizeTimeouts = [20, 40, 80, 200, 500, 1000, 1500, 2000, 3000];
    this.defaultScreenSize = { landscape: { width: 1920, height: 1120 }, portrait: { width: 940, height: 1200 } };
    this._defaultFontSize = parseFloat(window.getComputedStyle(document.documentElement).fontSize);
    this.onResize = new Event();
    this._onResize();
    window.addEventListener('resize', this.resizeWithTimeout.bind(this));
    window.addEventListener('fullscreenchange', this.resizeWithTimeout.bind(this));
  }
  async resizeWithTimeout() {
    this._clearResizeTimeouts();
    const mainTimeout = setTimeout(() => {
      if (UserAgentDetector.isMobile.any) {
        this._setMobileResizeTimeouts();
      }
      this._onResize();
    }, UserAgentDetector.isMobile.any ? 1 : 200);
    this._resizeTimeouts.push(mainTimeout);
  }

  _onResize() {
    let newInnerWidth = document.documentElement.clientWidth;
    let newInnerHeight = document.documentElement.clientHeight;

    if (this.innerWidth === newInnerWidth
      && this.innerHeight === newInnerHeight) return;

    this.innerWidth = newInnerWidth;
    this.innerHeight = newInnerHeight;

    if (this.innerWidth > this.innerHeight) {
      this.orientation = 'landscape';
    } else {
      this.orientation = 'portrait';
    }
    document.documentElement.style.fontSize = Math.min(this.innerWidth, this.defaultScreenSize[this.orientation].width / this.defaultScreenSize[this.orientation].height * this.innerHeight) * this._defaultFontSize / this.defaultScreenSize[this.orientation].width + 'px';
    this.onResize.call({ orientation: this.orientation });
  }

  _setMobileResizeTimeouts() {
    this._defaultResizeTimeouts.forEach((time, index) => {
      const timeouts = setTimeout(() => {
        this._onResize();
      }, time);
      this._resizeTimeouts.push(timeouts);
    });
  }

  _clearResizeTimeouts() {
    this._resizeTimeouts.forEach(timeout => clearTimeout(timeout));
  }
}
