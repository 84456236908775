import Event from '../../utils/event';

export default class NumberInput {
  constructor(element) {
    this.element = element;
    this.element.addEventListener('blur', this.onInputBlur.bind(this));
    this.element.addEventListener('focus', this.onInputFocus.bind(this));
    this.element.addEventListener('keydown', this.onInput.bind(this));
    this.onBlur = new Event()
  }

  set value(value) {
    this.element.value = value;
  }

  set readOnly(value) {
    this.element.readOnly = value;
    this.element.disabled = value;
  }

  onInput(event) {
    if (!(/\d|\.|Backspace/i).test(event.key)) {
      event.preventDefault();
    }
  }

  onInputFocus() {
    this.element.value = '';
  }

  onInputBlur(event) {
    this.onBlur.call(event);
  }
}
