export default class PartnerConfig {
  constructor() {
    this.lang = 'en';
    this.jackpotEnabled = true;
    this.iFrameHomeButton = false;
    this.cancelEnabled = true;
    this.showLimitsOnStart = false;
    this.showRulesOnStart = false;
    this.disableAutoplay = false;
    this.skipBlocked = false;
    this.roundDuration = null;
    this.minWinRankCoef = null;
    this.showOperatorBetsIds = false;
    this.disableBuyBonus = false;
    this.disableSpinBySpace = false;
    this.enableRealityCheck = false;
    this.lightMode = false;
    this.certificationName = 'default';
    this.session = {
      checkStatus: false,
      checkStatusInterval: 0,
    }
    this.gameConfig = {};
    this.messageEvents = {};
    this.infoPanel = {};
  }

  updateData(data, lang) {
    if (lang) this.lang = lang;

    if (!data) return;

    if (data.hasOwnProperty('jackpotEnabled'))
      this.jackpotEnabled = data.jackpotEnabled;

    if (data.hasOwnProperty('iframe_home_button')) {
      this.iFrameHomeButton = data.iframe_home_button;
    }

    if (data.hasOwnProperty('available_bets')) {
      this.available_bets = data.available_bets;
    }

    if (data.hasOwnProperty('maxWinLimit')) {
      this.maxWin = data.maxWinLimit;
    }

    if (data.hasOwnProperty('showMaxWinOnStart')) {
      this.showLimitsOnStart = data.showMaxWinOnStart;
    }

    if (data.hasOwnProperty('currency')) {
      this.currency = data.currency;
    }

    if (data.hasOwnProperty('cancelEnabled')) {
      this.cancelEnabled = data.cancelEnabled;
    }

    if (data.hasOwnProperty('showRulesOnStart')) {
      this.showRulesOnStart = data.showRulesOnStart;
    }
    if (data.hasOwnProperty('disableAutoplay')) {
      this.disableAutoplay = data.disableAutoplay;
    }
    if (data.hasOwnProperty('skipBlocked')) {
      this.skipBlocked = data.skipBlocked;
    }
    if (data.hasOwnProperty('roundDuration')) {
      this.roundDuration = data.roundDuration;
    }
    if (data.hasOwnProperty('showOperatorBetsIds')) {
      this.showOperatorBetsIds = data.showOperatorBetsIds;
    }
    if (data.hasOwnProperty('disableBuyBonus')) {
      this.disableBuyBonus = data.disableBuyBonus;
    }
    if (data.hasOwnProperty('disableSpinBySpace')) {
      this.disableSpinBySpace = data.disableSpinBySpace;
    }
    if (data.hasOwnProperty('session')) {
      this.session = data.session;
    }
    if (data.hasOwnProperty('game_config')) {
      this.gameConfig = data.game_config;
    }
    if (data.hasOwnProperty('messageEvents')) {
      this.messageEvents = data.messageEvents;
    }
    if (data.hasOwnProperty('displayRtp')) {
      this.infoPanel.displayRtp = data.displayRtp;
    }
    if (data.hasOwnProperty('showGameState')) {
      this.infoPanel.showGameState = data.showGameState;
    }
    if (data.hasOwnProperty('showStopwatch')) {
      this.infoPanel.showStopwatch = data.showStopwatch;
    }
    if (data.hasOwnProperty('showClock')) {
      this.infoPanel.showClock = data.showClock;
    }
    if (data.hasOwnProperty('certificationName')) {
      this.certificationName = data.certificationName;
    }
    if (data.hasOwnProperty('enableRealityCheck')) {
      this.enableRealityCheck = data.enableRealityCheck;
    }
    if (data.hasOwnProperty('lightMode')) {
      this.lightMode = data.lightMode;
    }
  }
}
