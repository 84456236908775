import ButtonSound from './ButtonSound';
import { EMPostMessageTypes } from '../platforms/everyMatrix/constants';
import LocalizationManager from '../i18n/LocalizationManager';
import eButtonsTypes from './eButtonsTypes';

export default class SideBar {
  constructor(container, config, toggleOpenedWindows, hasOpenedWindows) {
    container.insertAdjacentHTML('beforeend',
      `<div id="opSidebar" class="sideBar hideSideBar">` +
      `<div class="container">` +
      `${this.buttonOval('opButtonClose', LocalizationManager.getLocalizedText(`OPWrapperService.buttons.close`), ['close'])}` +
      `</div>` +
      `</div>`);

    this.config = config;
    this.container = container;
    this.config.buttonsPriority = {
      [eButtonsTypes.EBT_HOME]: 0,
      [eButtonsTypes.EBT_PAYTABLE]: 1,
      [eButtonsTypes.EBT_RULES]: 2,
      [eButtonsTypes.EBT_SETTINGS]: 3,
      [eButtonsTypes.EBT_SOUND]: 4,
      [eButtonsTypes.EBT_INFO]: 5,
      [eButtonsTypes.EBT_LEADERBOARD]: 6,
      [eButtonsTypes.EBT_GAME_LIMITS]: 7,
    }

    this.toggleOpened = toggleOpenedWindows;
    this.hasOpened = hasOpenedWindows;

    this.view = document.getElementById('opSidebar');
    this.sideBarContainer = this.view.getElementsByClassName('container')[0];
    this.buttonClose = document.getElementById('opButtonClose');
    this.buttonClose.addEventListener('click', this._onClose.bind(this));
    this.container.addEventListener('click', this.onOutsideClick.bind(this));
  }

  init() {
    this._tryInitHomeButton();
    this._tryInitLimitsButton();
    this.initButtons();
    this.inited = true;
  }

  show() {
    this.view.classList.remove('hideSideBar');
    this.container.classList.add('enable');
    this.toggleOpened('sideBar');
  }

  hide() {
    this.view.classList.add('hideSideBar');
    this.toggleOpened('sideBar');
    !this.hasOpened && this.container.classList.remove('enable');
  }

  toggle() {
    if (this.sideBarHidden) {
      this.show();
    } else {
      this.hide();
    }
  }

  onOutsideClick(event) {
    const path = event.path || event.composedPath();
    if (!this.sideBarHidden && !path.includes(this.view)) {
      this.hide();
    }
  }

  initButtons() {
    this.config.buttons.sort((a, b) => this.config.buttonsPriority[a.type] - this.config.buttonsPriority[b.type]);
    this.buttonClose.getElementsByClassName('text')[0].textContent = LocalizationManager.getLocalizedText(`OPWrapperService.buttons.close`);
    this.sideBarContainer.append(this.buttonsList(this.config.buttons));
  }

  updateButtons() {
    if(!this.inited) return;
    this.buttonsContainer.remove();
    this.initButtons();
  }

  addButton(data) {
    this._addButtons([data]);
    this.updateButtons()
  }

  buttonOval(id, sign, styles = []) {
    return (
      `<div id="${id}" class="buttonOval ${styles.join(' ')}">` +
      `<span class="text">${sign}</span>` +
      `</div>`)
  }

  buttonsList(buttons) {
    this.buttonsContainer = document.createElement('div');
    this.buttonsContainer.classList.add('buttonsContainer');

    buttons.map((item) => {
      if (item.type === eButtonsTypes.EBT_SOUND) {
        this.ButtonSound = new ButtonSound(item);
        this.buttonsContainer.append(this.ButtonSound.element);
      } else {
        this.buttonsContainer.append(this.button(item));
      }
    });
    return this.buttonsContainer;
  }

  button(data) {
    const button = document.createElement('div');
    button.classList.add('buttonContainer');
    if (this.config.buttons.length > 4) {
      button.classList.add('buttonContainer_s');
    }
    button.addEventListener('click', () => {
      this.hide();
      window.OPWrapperService.initConfig.onButtonClick();
      data.onClick();
    });
    button.insertAdjacentHTML('afterBegin',
      `<div class="icon ${data.styles && data.styles.join(' ')}"></div>` +
      `<div class="text">${LocalizationManager.getLocalizedText(`OPWrapperService.buttons.${data.type}`)}</div>` +
      `<div class="separator"></div>`
    );
    return button;
  };

  get sideBarHidden() {
    return this.view.classList.contains('hideSideBar')
  }

  isIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  getUrlParams(url) {
    const hashes = url.slice(url.indexOf('?') + 1).split('&');
    const params = {};
    hashes.map(hash => {
      const [key, val] = hash.split('=');
      params[key] = decodeURIComponent(val)
    });
    return params;
  };

  _addButtons(buttons = []) {
    this.config.buttons = [...this.config.buttons, ...buttons];
  }

  _tryInitLimitsButton() {
    if (window.OPWrapperService.config.available_bets && window.OPWrapperService.config.maxWin) {
      this.addButton({
        type: eButtonsTypes.EBT_GAME_LIMITS,
        styles: ['gameLimits'],
        onClick: window.OPWrapperService.gameLimitsWindow.show
      });
    }
  }

  _tryInitHomeButton() {
    const data = this._getHomeButtonData();
    if (data)
      this.addButton(data);
  }

  _getHomeButtonData() {
    const search = window.location.search;
    const history = window.history;
    const homeURL = this.getUrlParams(search).lobbyUrl;
    let info = { type: eButtonsTypes.EBT_HOME, styles: ['home'] };
    const iFrameButton = window.OPWrapperService.config.iFrameHomeButton;
    if (!this.isIframe() && (homeURL || window.history.length > 1)) {
      info.onClick = () => {
        if (homeURL) {
          window.top.location.href = homeURL;
        } else {
          history.back();
        }
      }
    } else if (homeURL && iFrameButton) {
      info.onClick = () => {
        window.top.location.href = homeURL;
        window.OPWrapperService.eventManager.dispatch(EMPostMessageTypes.PMT_CLOSED);
      }
    } else {
      console.warn(`No lobbyUrl presented: ${search}`);
      info = null;
    }

    return info;
  }

  _onClose() {
    window.OPWrapperService.initConfig.onButtonClick();
    this.hide();
  }
}
