import StatsModel from '../models/StatModel';

export default class ControllerCoefsHistory {
  constructor() {
    this.element = document.getElementById('coefs_history');

    this._createElements();

    StatsModel.onCoefsHistoryChanged.add(this.onCoefsChanged.bind(this));
  }

  onCoefsChanged() {
    this._createElements();
  }

  _createElements() {
    this.element.innerHTML = '';
    for (let i = 0; i < StatsModel.coefsHistory.length; i++) {
      const item = StatsModel.coefsHistory[i];
      this.element.innerHTML += this._createElement(item);
    }
    this.element.parentElement.scrollTo(0, 0);
  }

  _createElement(coef) {
    const additionClass = coef < 1.5 ? 'lose' : '';
    return `<div class="factor ${additionClass}">×${coef.toFixed(2)}</div>`
  }
}
