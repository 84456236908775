import ControllerBalance from './ControllerBalance';
import ControllerCounter from './ControllerCounter';
import BetBlock from './bet/BetBlock';
import GameModel from '../models/GameModel';
import ControllerCoefsHistory from './ControllerCoefsHistory';
import ControllerTable from './ControllerTable';
import ControllerScoreTable from './ControllerScoreTable';
import ControllerJackpot from './ControllerJackpot';
import ControllerMiniPreloader from './ControllerMiniPreloader';
import GameScaleManager from '../GameScaleManager';

export default class ControllerUI {
  constructor() {
    new ControllerBalance();
    new ControllerJackpot();
    new ControllerCounter();
    new ControllerCoefsHistory();
    new ControllerTable();
    new ControllerScoreTable();
    new ControllerMiniPreloader();

    this.bets = [];
    for (let i = 0; i < GameModel.bets.length; i++) {
      this.bets.push(new BetBlock(i, GameModel.bets[i]));
    }
    this._updateScroll();
    GameModel.onBetsChanged.add(this.onGameBetsChanged.bind(this));
    GameScaleManager.onResize.add(this._updateScroll.bind(this));
  }

  onGameBetsChanged() {
    for (const item of this.bets) {
      item.destroy();
    }
    this.bets = [];

    for (let i = 0; i < GameModel.bets.length; i++) {
      this.bets.push(new BetBlock(i, GameModel.bets[i]));
    }
    this._updateScroll();
  }

  _updateScroll() {
    if (GameScaleManager.orientation === 'portrait' || this.bets.length > 1) {
      document.body.style.overflowY = 'auto';
    } else if (window.getComputedStyle(document.body).overflowY !== 'hidden') {
      window.scrollTo({
        top: 0,
        behavior: 'instant'
      });
      document.body.style.overflowY = 'hidden';
    }
  }
}
