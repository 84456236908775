import BetContainer from './BetContainer';
import CoefContainer from './CoefContainer';
import ButtonBet from './ButtonBet';
import GameModel from '../../models/GameModel';
import SoundManager from '../../soundManager/SoundManager';
import eSoundsType from '../../preloaders/sounds/eSoundsType';
import { formatCurrency } from '../../utils/currency';
import ArcadelaraManager from '../../platforms/arcadelara/arcadelaraManager';

export default class BetBlock {
  constructor(id, model) {
    this.id = id;
    this.model = model;
    this.model.onChange.add(this.onGameModelChanged.bind(this));
    this.template = document.getElementById('bet_block_template');
    this.element = this.template.cloneNode(true);
    this.element.style.setProperty('display', 'flex');
    this.element.setAttribute('id', `bet_block_${this.id}`);
    this.template.parentNode.appendChild(this.element);
    this.template.style.setProperty('display', 'none');

    this.buttonAddBet = this.element.getElementsByClassName('button_add_bet')[0];
    if (GameModel.bets.length > 1) {
      this.buttonAddBet.innerText = '×';
      this.buttonAddBet.addEventListener('click', this.onRemoveBetClicked.bind(this));
      this.buttonAddBet.disabled = this.model.betSubmitted;
    } else {
      this.buttonAddBet.addEventListener('click', this.onAddBetClicked.bind(this));
    }

    const predefinedBets = this.element.getElementsByClassName('predefined_bets')[0];
    this.predifinedButtons = [];
    for (let i = 0; i < 4; i++) {
      const button = predefinedBets.getElementsByClassName(`button_${i}`)[0];
      const amount = this.model.betsButtons[i];
      button.addEventListener('click', () => this.onPredefinedBetClicked(amount));
      button.disabled = this.model.betSubmitted || window.OPWrapperService.freeBetsController.isFirstFreeBet || window.OPWrapperService.freeBetsController.isActive;;
      this.predifinedButtons.push(button);
      button.innerText = `${formatCurrency(amount, GameModel.decimal)} ${GameModel.currency}`;
    }

    this.betContainer = new BetContainer(this.element.getElementsByClassName('bet_container')[0], model);
    this.coefContainer = new CoefContainer(this.element.getElementsByClassName('coef_container')[0], model);
    this.buttonBet = new ButtonBet(this.element.getElementsByClassName('button_bet')[0], model);
  }

  onGameModelChanged() {
    for (const item of this.predifinedButtons) {
      item.disabled = this.model.betSubmitted || window.OPWrapperService.freeBetsController.isFirstFreeBet || window.OPWrapperService.freeBetsController.isActive;;
    }
    this.buttonAddBet.disabled = this.model.betSubmitted && GameModel.bets.length > 1;
  }

  onAddBetClicked() {
    SoundManager.play(eSoundsType.EST_CLICK, 1 + 2);
    ArcadelaraManager.onTrySecondBet();
  }

  onRemoveBetClicked() {
    SoundManager.play(eSoundsType.EST_CLICK, 1 + 2);
    if (this.model.roundID) {
      console.error('Can\'t delete this bet');
      return;
    }
    GameModel.removeBet(this.model);
  }

  onPredefinedBetClicked(amount) {
    SoundManager.play(eSoundsType.EST_CLICK, 1 + 2);
    this.model.betAmount = amount;
  }

  destroy() {
    this.buttonBet.destroy();
    this.betContainer.destroy();
    this.coefContainer.destroy();
    this.element.parentNode.removeChild(this.element);
  }
}
