import GlobalDispatcher from '../../events/GlobalDispatcher';
import eEventType from '../../events/eEventType';
import GameModel from '../../models/GameModel';
import eWSEventId from '../../api/eWSEventId';

export default new class ArcadelaraManager {
  constructor() {
    this.addListeners();
    this.maxBet = 1;
  }

  addListeners() {
    GlobalDispatcher.add(eEventType.EET_BET_CHANGED, this.onBetAmountChanged, this);
    GlobalDispatcher.add(eEventType.EET_JACKPOT_ENDED, this.onJackpotEnded, this);
    GlobalDispatcher.add(eWSEventId.EWEI_BALANCE, this.onBalanceChanged, this);
  }

  onBetAmountChanged({ params: value }) {
    if (value > 1) {
      this._sendMassage('offerRealMoneyGame', 'betAmountIncrease');
    }
  }

  onJackpotEnded() {
    this._sendMassage('offerRealMoneyGame', 'jackpot');
  }

  checkBetAmount(value) {
    let result = true;
    if (value > this.maxBet) {
      this._sendMassage('offerRealMoneyGame', 'betAmountIncrease');
      result = false;
    }
    return result;
  }

  onTrySecondBet() {
    this._sendMassage('offerRealMoneyGame', 'trySecondBet');
  }

  onBalanceChanged(data) {
    const balance = data.params.balance;
    this._sendMassage('balanceChanged', 'balanceChanged',{ balance });
  }

  _sendMassage(type, reason, info) {
    const message = { type, sender: 'fighterGame' };
    if (reason != null) message.reason = reason;
    if (info != null) message.info = info;
    window.top.postMessage(message, '*');
  }
};
