import ImagePreloader from '../../preloaders/images/ImagePreloader';
import eImageType from '../../preloaders/images/eImageType';
import ControllerMovingBGBase from './ControllerMovingBGBase';

export default class ControllerSky extends ControllerMovingBGBase {
  constructor(container) {
    super(container);
    this.container.name = 'sky_container';
    this.mergeOffset = 650;
    this.stepDevider = 4;
    this.currentSky = eImageType.EIT_SKY_0;
    this.needDestroyItems = [];

    for (let i = 0; i < 5; i++) {
      const sky = ImagePreloader.createImage(this.currentSky);
      sky.x = -this.mergeOffset + i * 1200;
      this.container.addChild(sky);
      if (i === 3) this.bg = sky;
      if (i === 4) this.bg1 = sky;
    }

    const mountain = ImagePreloader.createImage(eImageType.EIT_SAND_MOUNTAIN_2);
    mountain.x = 3700;
    mountain.y = this.container.height - mountain.height;
    this.container.addChild(mountain);
  }

  onUpdate() {
    const containerX = Math.round(this.container.x) - this.zoomOffset;

    if (containerX < -4300 && this.currentSky !== eImageType.EIT_SKY_1) {
      this.currentSky = eImageType.EIT_SKY_1;
      this._spawnMountain();
    }

    if (containerX < -(this.bg.x + this.bg.width)) {
      this.bg.x = this.bg1.x + this.bg1.width - this.mergeOffset;
      this.bg.texture = ImagePreloader.getTexture(this.currentSky);
    }

    if (containerX < -(this.bg1.x + this.bg1.width)) {
      this.bg1.x = this.bg.x + this.bg.width - this.mergeOffset;
      this.bg1.texture = ImagePreloader.getTexture(this.currentSky);
    }

  }

  _spawnMountain() {
    const x = Math.max(this.bg.x, this.bg1.x) + this.bg.width + 200;
    const mountain = ImagePreloader.createImage(eImageType.EIT_ICY_MOUNTAIN_0);
    mountain.x = x;
    mountain.y = this.container.height - mountain.height + 10;
    const mountain1 = ImagePreloader.createImage(eImageType.EIT_ICY_MOUNTAIN_2);
    mountain1.x = x + mountain.width;
    mountain1.y = this.container.height - mountain1.height;
    const mountain2 = ImagePreloader.createImage(eImageType.EIT_ICY_MOUNTAIN_1);
    mountain2.x = mountain1.x + mountain1.width;
    mountain2.y = this.container.height - mountain2.height;
    this.container.addChild(mountain, mountain1, mountain2);
    this.needDestroyItems.push(mountain, mountain1, mountain2);
  }

  reset() {
    super.reset();
    this.bg.x = -2 * this.mergeOffset + 3 * 1200;
    this.bg1.x = -2 * this.mergeOffset + 4 * 1200;

    this.currentSky = eImageType.EIT_SKY_0;
    this.bg.texture = ImagePreloader.getTexture(this.currentSky);
    this.bg1.texture = ImagePreloader.getTexture(this.currentSky);

    for (const item of this.needDestroyItems) {
      item.destroy();
    }
    this.needDestroyItems = [];
  }
}
