import Config from './Config';
import UserAgentDetector from '../utils/UserAgentDetector';

export default new class ScaleManager {
  constructor() {
  }

  init(data, config) {
    if (this.initialized) return;
    this.initialized = true
    this.config = config ? { ...Config, ...config } : Config;
    this.currentSizes = null;
    this._currentData = null;
    this._resizeTimeouts = [];
    this._defaultResizeTimeouts = [20, 40, 80, 200, 500, 1000, 1500, 2000, 3000];
    this.gameContainer = document.getElementById('container');

    this.data = data || null;
    this.calculateCurrentSize();
    this.addListeners();
    this.onResize()
  }

  addListeners() {
    window.addEventListener('resize', this.resizeWithTimeout.bind(this));
    window.addEventListener('fullscreenchange', this.resizeWithTimeout.bind(this));
  }

  async resizeWithTimeout() {
    this._clearResizeTimeouts()
    const mainTimeout = setTimeout(() => {
      if (UserAgentDetector.isMobile.any) {
        this._setMobileResizeTimeouts()
      }
      this.onResize();
    }, UserAgentDetector.isMobile.any ? 1 : 200);
    this._resizeTimeouts.push(mainTimeout);
  }

  onResize() {
    let  newInnerWidth = this.gameContainer.clientWidth;
    let newInnerHeight = this.gameContainer.clientHeight;

    if (this.innerWidth === newInnerWidth
      && this.innerHeight === newInnerHeight) return;

    this.innerWidth = newInnerWidth;
    this.innerHeight = newInnerHeight;

    this.checkOrientation();
    this.calculateCurrentSize();

    this.data.app.view.style.width = `${this.innerWidth}px`;
    this.data.app.view.style.height = `${this.innerHeight}px`;

    const scaleRatio = this.data.scaleRatio || 1; //UserAgentDetector.isMobile.any ? 2 : 1;

    const finalInnerWidth = this.innerWidth * scaleRatio;
    const finalInnerHeight = this.innerHeight * scaleRatio;

    this.data.app.renderer.resize(finalInnerWidth, finalInnerHeight);

    const scale = Math.min(finalInnerWidth / this.safeZone.width, finalInnerHeight / this.safeZone.height);
    this.data.app.stage.scale.set(scale, scale);

    this.data.app.stage.x = (finalInnerWidth - (this.safeZone.width * scale)) / 2 - (this.safeZone.left) * scale;
    this.data.app.stage.y = (finalInnerHeight - (this.safeZone.height * scale)) / 2 - this.safeZone.top * scale;
    this._currentData = {
      orientation: this.orientation,
      isPortrait: this.orientation === 'V',
      isLandscape: this.orientation === 'H',
      canvasWidth: this.data.app.view.width,
      canvasHeight: this.data.app.view.height,
      gameWidth: this.currentSizes.width,
      gameHeight: this.currentSizes.height,
      stageX: this.data.app.stage.x,
      stageY: this.data.app.stage.y,
      stageHeight: this.data.app.stage.height,
      stageWidth: this.data.app.stage.width,
      isMobile: UserAgentDetector.isMobile.any,
      scaleWidth: finalInnerWidth / scale / this.currentSizes.width,
      scaleHeight: finalInnerHeight / scale / this.currentSizes.height,
      innerWidth: this.innerWidth,
      innerHeight: this.innerHeight,
      scale
    };
    window.OPWrapperService.eventManager.dispatch(window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE, this.currentData);
  }

  checkOrientation() {
    if (this.innerWidth < this.innerHeight && UserAgentDetector.isMobile.any) {
      this.orientation = 'V';
      this.shiftX = (this.config.CANVAS_WIDTH_PORTRAIT - this.config.SAFE_WIDTH_PORTRAIT) / 2;
      this.shiftY = (this.config.CANVAS_HEIGHT_PORTRAIT - this.config.SAFE_HEIGHT_PORTRAIT) / 2;
      this.safeZone = { left: this.shiftX, top: this.shiftY, width: this.config.SAFE_WIDTH_PORTRAIT, height: this.config.SAFE_HEIGHT_PORTRAIT };
      this.gameRatio = this.config.CANVAS_WIDTH_PORTRAIT / this.config.CANVAS_HEIGHT_PORTRAIT
    } else {
      this.orientation = 'H';
      this.shiftX = (this.config.CANVAS_WIDTH_LANDSCAPE - this.config.SAFE_WIDTH_LANDSCAPE) / 2;
      this.shiftY = (this.config.CANVAS_HEIGHT_LANDSCAPE - this.config.SAFE_HEIGHT_LANDSCAPE) / 2;
      this.safeZone = { left: this.shiftX, top: this.shiftY, width: this.config.SAFE_WIDTH_LANDSCAPE, height: this.config.SAFE_HEIGHT_LANDSCAPE };
      this.gameRatio = this.config.CANVAS_WIDTH_LANDSCAPE / this.config.CANVAS_HEIGHT_LANDSCAPE
    }
  }

  calculateCurrentSize() {
    let windowRatio = this.gameContainer.offsetWidth / this.gameContainer.offsetHeight;
    this.currentSizes = UserAgentDetector.isMobile.any ? (windowRatio >= 1 ? this.data.gameSize.mobileLandscape : this.data.gameSize.mobilePortrait) : this.data.gameSize.desktop;
    if (!this.currentSizes) {
      this.currentSizes = this.data.gameSize;
    }
  }


  get currentData() {
    return this._currentData;
  }

  _setMobileResizeTimeouts() {
    this._defaultResizeTimeouts.forEach((time, index) => {
      const timeouts = setTimeout(() => {
        this.onResize()
      }, time);
      this._resizeTimeouts.push(timeouts);
    })
  }

  _clearResizeTimeouts() {
    this._resizeTimeouts.forEach(timeout => clearTimeout(timeout));
  }

}
