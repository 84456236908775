import StatModel from '../models/StatModel';
import SoundManager from '../soundManager/SoundManager';
import eSoundsType from '../preloaders/sounds/eSoundsType';
import GlobalDispatcher from '../events/GlobalDispatcher';
import eWSEventId from '../api/eWSEventId';
import eEventType from '../events/eEventType';
import Constants from '../Constants';
import { formatCurrency } from '../utils/currency';

export const eTabType = {
  ETT_BETS: 'bets',
  ETT_MY_BETS: 'myBets',
};

export default class ControllerTable {
  constructor() {
    this.currentTab = eTabType.ETT_BETS;
    this.tabsContainer = document.getElementsByClassName('tabs__head')[0];

    this.tabsButtons = this.tabsContainer.getElementsByTagName('li');
    for (const item of this.tabsButtons) {
      item.addEventListener('click', this._onTabClicked.bind(this));
    }

    this.jackpotTitle = document.getElementById('jackpotTitle');
    this.jackpotTitle.style.setProperty('display', 'none');
    this.jackpotTitle.textContent = window.OPWrapperService.localizations.getLocalizedText(window.OPWrapperService.config.jackpotEnabled ? 'jackpot_winners' : 'boss_reward_winners')

    this.element = document.getElementById('table');

    this.statBlock = document.getElementById('stat_table');

    this.body = document.getElementById('tbody');
    this.thead = this.element.getElementsByTagName('thead')[0];

    this._redrawRows();

    StatModel.onBetsInfoChanged.add(this._onBetsInfoChanged.bind(this));
    window.OPWrapperService.localizations.addLocalizationChangedCallback(this._updateLocalization, this);
    this._updateLocalization();
    GlobalDispatcher.add(eWSEventId.EWEI_FINISH, this._onFinish, this);
    GlobalDispatcher.add(eEventType.EET_RESET, this.reset, this);

    document.addEventListener('visibilitychange', this._onVisibilityChange.bind(this));
  }

  reset() {
    this.tabsButtons[0].parentNode.style.setProperty('display', 'flex');
    this.jackpotTitle.style.setProperty('display', 'none');
    this.statBlock.classList.remove('jackpot');
    this.showJackpot = false;

    this._redrawRows();

    clearTimeout(this.timeoutShowJackpot);
  }

  _onVisibilityChange() {
    if(!document.hidden) this.reset();
  }

  _onBetsInfoChanged() {
    setTimeout(this._redrawRows.bind(this), 0); //this is hack to wait for all events fired
  }

  _onTabClicked(event) {
    SoundManager.play(eSoundsType.EST_CLICK, 1 + 2);
    for (const item of this.tabsButtons) {
      item.classList.remove('active');
    }
    event.target.parentNode.classList.add('active');
    this.currentTab = event.target.getAttribute('data-type');
    this._redrawRows();
  }

  _onFinish(data) {
    if (data.params.reason !== 'jackpot' || data.params.awards.length === 0) return;

    this.showJackpot = true;

    this.timeoutShowJackpot = setTimeout(() => this._showJackpotWinners(data.params.awards, data.params.coef), Constants.BEFORE_JACKPOT_SIGN_TIMEOUT);
  }

  _showJackpotWinners(winners, coef) {
    this.tabsButtons[0].parentNode.style.setProperty('display', 'none');
    this.jackpotTitle.style.setProperty('display', 'block');
    this.statBlock.classList.add('jackpot');

    this._updateLocalization('jackpot');
    while (this.body.firstChild) {
      this.body.removeChild(this.body.firstChild);
    }

    // console.log(winners)
    winners = winners.sort((item) => StatModel.myBetsInfo.findIndex((bet) => bet.betId === item.betId) === -1 ? 1 : -1);
    // console.log(winners)
    for (const item of winners) {
      const row = this.body.insertRow();
      if (StatModel.myBetsInfo.findIndex((bet) => bet.betId === item.betId) !== -1) row.classList.add('jackpotCurrent');
      if (OPWrapperService.config.showOperatorBetsIds) {
        this._createCell(row, item.operatorBetId || '---');
      } else {
        this._createCell(row, item.clientId);
      }
      this._createCell(row, item.amount);
      this._createCell(row, formatCurrency(item.amount * coef, item.decimals, false));
      this._createCell(row, formatCurrency(item.jackpot_total * item.jackpot_percent, item.decimals, false));
    }
  }

  _redrawRows() {
    if (this.showJackpot) return;

    this._updateBets();
  }

  _updateBets() {
    this._updateLocalization();
    let rowList = [ ...this.body.children ];
    const isAllBets = this.currentTab === eTabType.ETT_BETS;
    const betsInfo = StatModel[isAllBets ? 'betsInfo' : 'myBetsInfo'];
    rowList = this._checkAndRemoveNotExistRows(rowList, betsInfo, isAllBets);
    for (const item of betsInfo) {
      let row = rowList.find(row => row.betId === item.betId);
      if (!row) {
        row = this._createRow(item, isAllBets);
      }
      const rowChildren = row.children;
      let cellIndex = 0;
      if (item.isCurrentPlayer && isAllBets) row.style.setProperty('color', '#DDC542');
      if (item.coef && item.coef >= 1) row.classList.add('collected');

      if (OPWrapperService.config.showOperatorBetsIds && row.betData.operatorBetId !== item.operatorBetId) {
        row.betData.operatorBetId = item.operatorBetId;
        rowChildren[cellIndex].textContent = item.operatorBetId || '---';
      }
      if ((!OPWrapperService.config.showOperatorBetsIds || !isAllBets) && (isAllBets ? row.betData.clientId : row.betData.time) !== (isAllBets ? item.clientId : item.time)) {
        const key = isAllBets ? 'clientId' : 'time';
        row.betData[key] = item[key];
        rowChildren[cellIndex].textContent = item[key];
      }
      cellIndex++;
      if (row.betData.amount !== item.amount) {
        row.betData.amount = item.amount;
        rowChildren[cellIndex].textContent = `${formatCurrency(item.amount, item.decimals, false)} ${item.currency}`;
      }
      cellIndex++;
      const coef = item.coef && item.coef >= 1 ? item.coef : '---';
      if (row.betData.coef !== coef) {
        row.betData.coef = coef;
        rowChildren[cellIndex].textContent = coef;
      }
      cellIndex++;
      const winAmount = item.winAmount && item.winAmount > 0 ? `${formatCurrency(item.winAmount, item.decimals, false)} ${item.currency}` : '---';
      if (row.betData.winAmount !== winAmount) {
        row.betData.winAmount = winAmount;
        rowChildren[cellIndex].textContent = winAmount;
      }
    }
  }

  _checkAndRemoveNotExistRows(rowList, betsInfo, isAllBets) {
    return rowList.filter(row => {
      if (!betsInfo.find(bet => bet.betId === row.betId) || !isAllBets) {
        row.remove();
        return false;
      } else {
        return true;
      }
    });
  }

  _createRow(item, isAllBets) {
    const row = this.body.insertRow(item.isCurrentPlayer && isAllBets ? 0 : -1);
    row.insertCell();
    row.insertCell();
    row.insertCell();
    row.insertCell();
    row.betId = item.betId;
    row.betData = {};
    return row;
  }

  _createCell(row, text) {
    const cell = row.insertCell();
    cell.innerHTML = text;
    return cell;
  }

  _updateLocalization(type = 'default') {
    const header = this.thead.rows[0];
    const isJackpot = type === 'jackpot';
    const isAllBets = this.currentTab === eTabType.ETT_BETS;
    const localizationManager = window.OPWrapperService.localizations;
    header.cells['tabs_header__operator_tx_id'].style.display = OPWrapperService.config.showOperatorBetsIds ? 'block' : 'none';
    header.cells['tabs_header__users_name'].style.display = OPWrapperService.config.showOperatorBetsIds && (isAllBets || isJackpot) ? 'none' : 'block';
    if (OPWrapperService.config.showOperatorBetsIds) header.cells['tabs_header__operator_tx_id'].innerText = localizationManager.getLocalizedText('operator_tx_id');
    header.cells['tabs_header__users_name'].innerText = localizationManager.getLocalizedText(isAllBets || isJackpot ? 'username' : 'time');
    header.cells['tabs_header__bets'].innerText = localizationManager.getLocalizedText('bet_column');
    header.cells['tabs_header__takes'].innerText = localizationManager.getLocalizedText(isJackpot ? 'win_amount' : 'take_coef');
    header.cells['tabs_header__wins'].innerText = isJackpot ? 'jackpot' : localizationManager.getLocalizedText('win_amount');

    for (const button of this.tabsButtons) {
      const a = button.getElementsByTagName('a')[0];
      const type = a.getAttribute('data-type');
      a.innerText = localizationManager.getLocalizedText(type === eTabType.ETT_BETS ? 'players' : 'my_bets');
    }
  }
}
