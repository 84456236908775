import LocalizationManager from '../i18n/LocalizationManager';
import { errorTypes } from '../utils/errorTypes';

export default class MessageWindow {
  constructor(container) {
    this.needReload = true;
    this.errors = errorTypes;

    container.insertAdjacentHTML('beforeend',
      `<div id="messageView" class="blocker">` +
        `<div class="messageContainer">`+
          `<div id="messageTitle">Title</div>` +
          `<div id="messageDesc">Some error described here</div>` +
          `<div id="buttonOk">OK</div>` +
        `</div>` +
      `</div>`
    );

    this.view = document.getElementById('messageView');
    this.title = document.getElementById('messageTitle');
    this.desc = document.getElementById('messageDesc');
    this.buttonOk = document.getElementById('buttonOk');
    this.buttonOk.addEventListener('click', () => {
      if (this.needReload) window.location.reload();
      this.view.style.setProperty('display', 'none');
      window.OPWrapperService.initConfig.onButtonClick();
    })
  }

  show(title, description, showOkButton = false, needReload = true) {
    this.needReload = needReload;
    this.buttonOk.style.setProperty('display', showOkButton ? 'block' : 'none');
    this.view.style.setProperty('display', 'block');
    this.title.innerText = title;

    if (description == null) description = 'No error message provided';
    this.desc.innerText = description;
  }

  showError (code, messageFallback = null) {
    let error = Object.values(this.errors).find(el => el.CODE === code);
    let titleLocalizationKey = 'OPWrapperService.errors.title';
    let messageLocalizationKey = error ? `OPWrapperService.errors.error_${code}` : null;
    let title = LocalizationManager.getLocalizedText(titleLocalizationKey);
    let message = LocalizationManager.getLocalizedText(messageLocalizationKey);
    let showOk = error ? error.SHOW_OK_BUTTON : true;
    let needToReload = error ? error.NEED_RELOAD : true;

    if (title === titleLocalizationKey || !title) {
      title = 'Error';
    }

    title = code ? `${title} (${code})` : title;

    if ((message === messageLocalizationKey || !message)) {
      message = messageFallback ? messageFallback : 'Server error';
    }

    window.OPWrapperService.eventManager.dispatch(
      window.OPWrapperService.eventManager.postMessageTypes.PMT_ERROR_MESSAGE,
      {
        code,
        message
      }
    );

    this.show(title, message, showOk, needToReload);
  }
}
