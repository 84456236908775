import TweenMax from 'gsap';
import GameModel from '../models/GameModel';

export default class ControllerWinLabel {
  constructor(x, y) {
    this.label = new PIXI.BitmapText('0', {
      fontName: 'goldFont',
      fontSize: 100,
      align: 'center'
    })
    this.label.anchor.set(0.5, 0.5);
    this.label.x = 3130;
    this.label.y = 2050;
    this.label.visible = false;

    this.tryAgain = new PIXI.Text('YOU COULD WIN JACKPOT \n IN REAL GAME', {
      font: 'Ubuntu',
      fontSize: 48,
      fontWeight: 900,
      align: 'center',
      fill: '#6FEFAC',
      stroke: '#793a14',
      strokeThickness: 10,
      lineJoin: 'round',
    });
    this.tryAgain.anchor.set(0.5, 0.5);
    this.tryAgain.x = 3130;
    this.tryAgain.y = 2050;
    this.tryAgain.visible = false;
  }

  showAnimation(value, duration, delay) {
    const temp = { value: 0 };
    const decimals = value < 10 ? GameModel.decimal : 0;
    this.tween = TweenMax.to(temp, duration, {
      value,
      onStart: () => this.label.visible = true,
      onUpdate: () => this.label.text = temp.value.toFixed(decimals),
    })
      .delay(delay);
  }

  hide() {
    if(this.tween) this.tween.kill();
    this.label.visible = false;
    this.tryAgain.visible = false;
  }
}
