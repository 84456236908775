import eWSEventId from './eWSEventId';
import GlobalDispatcher from '../events/GlobalDispatcher';
import { getUrlParam } from '../utils/url';
import ConnectionStressTesting from './connectionSressTesting';
import ManagerErrors from '../errors/ManagerErrors';
import { requestReAuth } from './wsRequests';

window.ConnectionStressTesting = ConnectionStressTesting;

export class SocketController {
  constructor(url, onMessage) {
    this.requestId = 0;
    this.responseCallbacks = {};
    this.callStack = [];
    this.closed = true;

    this.conectedData = [url, onMessage];

    this.connect(...this.conectedData);
  }

  connect(url, onMessage) {
    this.ws = new WebSocket(url);

    this.ws.addEventListener('message', onMessage);
    this.ws.addEventListener('message', this.onMessage.bind(this));
    this.ws.addEventListener('open', this.onOpen.bind(this));
    this.ws.addEventListener('error', this.onError.bind(this));
    this.ws.addEventListener('close', this.onClose.bind(this));
  }

  close() {
    this.closed = true;
    this.ws.close();
  }

  reconnect() {
    this.connect(...this.conectedData);
  }

  onOpen() {
    this.closed = false;
    const authIndex = this.callStack.findIndex((item) => item.message.id === eWSEventId.EWEI_AUTH);
    if (authIndex !== -1) {
      const authMessage = this.callStack.splice(authIndex, 1)[0];
      const resolve = () => {
        authMessage.resolve();
        this.onOpen();
      };
      this._sendWithPromise(authMessage.message, resolve, authMessage.reject);
    } else {
      while (this.callStack.length > 0) {
        const item = this.callStack.shift();
        this._sendWithPromise(item.message, item.resolve, item.reject);
      }
    }
  }

  onError(event) {
    console.error(event.message);
    window.OPWrapperService.showError(window.OPWrapperService.errors.SOCKET_DISCONNECTED.CODE);
  }

  onClose(event) {
    this.closed = true;
  }

  async onMessage(event) {
    let data = '';
    data = JSON.parse(event.data);

    if (data.code) {
      console.error(data.message);
      if (data.hasOwnProperty('requestId')) {
        this.responseCallbacks[data.requestId].reject(data);
        delete this.responseCallbacks[data.requestId];
        return;
      }
    }

    if (data.hasOwnProperty('requestId')) {
      if (data.requestId === -1) return;
      this.responseCallbacks[data.requestId].resolve(data);
      delete this.responseCallbacks[data.requestId];
    }
  }

  send(message) {
    if (!navigator.onLine) {
      window.OPWrapperService.showError(window.OPWrapperService.errors.NO_CONNECTION.CODE);
      throw new Error('No Internet connection.')
    }
    return new Promise((resolve, reject) => {
      try {
        this._sendWithPromise(message, resolve, reject);
      } catch (e) {
        console.warn(e.message);
        this.callStack.push({ message, resolve, reject });
      }
    });
  }

  _sendWithPromise(message, resolve, reject) {
    if (this.closed) throw Error('WebSocket is already in CLOSING or CLOSED state');
    this.ws.send(JSON.stringify({ ...message, requestId: this.requestId }));
    this.responseCallbacks[this.requestId] = { resolve, reject };
    this.requestId++;
  }
}

export default new class ManagerWS {
  constructor() {
    this.lastErrorCode = 0;

    this.connect();
  }

  connect() {
    let prefix = '';
    const api = getUrlParam('api');
    if (/.stage/.test(api)) {
      prefix = 'stage.';
    } else if (/.dev/.test(api)) {
      prefix = 'dev.';
    }

    let asiaPrefix = '';
    if (/asia/.test(api)) asiaPrefix = '-asia';
    // this.ws = new SocketController(`wss://fighter.${prefix}onlyplay.net/ws/fighter`, this.onMessage.bind(this));
    // this.ws = new SocketController(`wss://fighter-service.${prefix}onlyplay.net/${getUrlParam('pid')}/fighter`, this.onMessage.bind(this));
    this.ws = new SocketController(`wss://ws-crash${asiaPrefix}-fighter.${prefix}onlyplay.net/${getUrlParam('pid')}/fighter`, this.onMessage.bind(this));
    // this.ws = new SocketController('ws://192.168.1.39:12010/fighter', this.onMessage.bind(this));
    // this.ws = new SocketController('ws://localhost:12010/fighter', this.onMessage.bind(this));
  }

  async onMessage(event) {
    let data = '';
    data = JSON.parse(event.data);

    if (data.code) {
      this.lastErrorCode = data.code;
      ManagerErrors.handleError(data);
    }

    if (data.id === eWSEventId.EWEI_RECONNECT) {
      this.reconnect();
      return;
    }

    if ([
      eWSEventId.EWEI_BET,
      eWSEventId.EWEI_BALANCE
    ].includes(data.id)) {
      this._updateFreeBets(data.freebets);
    }

    if (Object.values(eWSEventId).includes(data.id)) {
      GlobalDispatcher.dispatch(data.id, data);
    } else {
      console.warn(`Unhandled ID: ${data.id}`);
    }
  }

  send(message) {
    return this.ws.send(message);
  }

  reconnect() {
    this.ws.close();
    requestReAuth();
    setTimeout(() => {
      this.ws.reconnect();
    }, +getUrlParam('reconnectTimeout') || +getUrlParam('RT') || 1000);
  }

  _updateFreeBets (freeBetsData) {
    window.OPWrapperService.freeBetsController.setData(freeBetsData);
    window.OPWrapperService.freeBetsController.show();
    window.OPWrapperService.freeBetsController.updateTotalWin();
  }
};
