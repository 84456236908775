export default {
  EET_RESET: 'reset',
  EET_CHANGE_GROUND_TEXTURE: 'change_bg_texture',
  EET_BACKGROUND_CHANGE_COMING: 'background_change_coming',
  EET_COMING_GROUND: 'coming_ground',
  EET_ANIMATE_START_WAITING: 'animate_start_waiting',
  EET_ANIMATE_DEFLECTOR_ON: 'animate_deflector_on',
  EET_BET_CHANGED: 'bet_changed',
  EET_JACKPOT_ENDED: 'jackpot_ended'
}
