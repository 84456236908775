import Clock from './items/clock';
import StopwatchInfoItem from './items/StopwatchInfoItem';
import GameState from './items/GameState/GameState';
import { getUrlParam } from '../utils/url';
import DisplayRtp from './items/DisplayRtp';
import eGameStateTypes from './items/GameState/eGameStateTypes';

export default class InfoPanel {
  constructor(initConfig, config) {
    // config.infoPanel = {showGameState: true, playTimeEnabled: true, showClock: true, displayRtp: '22.2%'}
    this.infoPanelConfig = config.infoPanel;
    this.initConfig = initConfig;
    this.gameStateTypes = eGameStateTypes;
    if (getUrlParam('pid') == 1 && initConfig.hasOwnProperty('clock') && initConfig.clock.enabled || Object.keys(this.infoPanelConfig).length) {
      this.init()
    }
  }

  init() {
    this.view = document.createElement('div');
    this.view.classList.add('opInfoPanel');
    document.body.insertAdjacentElement('beforeend', this.view);

    this.tryAddItem(Clock, (getUrlParam('pid') == 1 && this.initConfig.clock && this.initConfig.clock.enabled ) || this.infoPanelConfig.showClock);
    if (Object.keys(this.infoPanelConfig).length !== 0) {
      this.gameState = this.tryAddItem(GameState, this.infoPanelConfig.showGameState);
      this.tryAddItem(StopwatchInfoItem, this.infoPanelConfig.showStopwatch);
      this.tryAddItem(DisplayRtp, this.infoPanelConfig.displayRtp);
    }
    const container = document.querySelector('#container');
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      () =>  container.style.top = `${this.view.clientHeight}px`,
      this
    );
  }

  tryAddItem(item, itemData) {
    return itemData ? new item(this.view, itemData, this.infoPanelConfig) : undefined;
  }

  setGameState(state) {
    if (this.gameState) this.gameState.setGameState(state)
  }
}
