import MainRealityCheckPopup from './mainRealityCheckPopup';
import { getUrlParam } from '../utils/url';
import Stopwatch from '../utils/Stopwatch';
import StartRealityCheckPopup from './startRealityCheckPopup';

export default class RealityCheck {
  constructor(container) {
    this.container = container;
    this._winAmount = 0;
    this._betAmount = 0;
    this._blockedGame = false;
    this._blockedRealityCheck = false;
    this.inited = false;
    this.init = this.init.bind(this);
    this.show = this.show.bind(this);
    this._onKeydown = this._onKeydown.bind(this);
    this.onActiveTimeout = this.onActiveTimeout.bind(this);
    this.onClickContinue = this.onClickContinue.bind(this);
    this.onClickContinue = this.onClickContinue.bind(this);
  }

  get blockedGame() {
    return this._blockedGame;
  }

  set blockedRealityCheck(value) {
    this._blockedRealityCheck = value;
    if (!value) this.tryShow();
  }

  get blockedRealityCheck() {
    return this._blockedRealityCheck;
  }

  init(partnerConfig) {
    if (!partnerConfig.enableRealityCheck) return
    this.timeouts = window.realityCheckTimeouts ? window.realityCheckTimeouts : [15, 30, 45, 60];
    this.partnerConfig = partnerConfig;
    this.container.insertAdjacentHTML('beforeend',
      `
      <div id="realityCheckView" class="blocker">
      </div>`
    );

    this.view = document.getElementById('realityCheckView');
    this.mainPopup = new MainRealityCheckPopup(this.view, this.timeouts, this.partnerConfig, this.onActiveTimeout, this.onClickContinue, this.onExitClick);
    this.startPopup = new StartRealityCheckPopup(this.view, this.timeouts, this.onActiveTimeout, this.onClickContinue);
    this.onActiveTimeout(this.timeouts[0]);
    Stopwatch.onUpdateTime.add(this.mainPopup.updateGameTime)
    this.show(true);
    this.inited = true;
  }

  addWin(value) {
    if (this.inited && typeof(value) === 'number') this._winAmount += value;
  }

  addBet(value) {
    if (typeof(value) === 'number') this._betAmount += value;
  }

  show(firstOpened = false) {
    this.view.style.setProperty('visibility', 'visible');
    this.view.style.setProperty('opacity', '1');
    if (firstOpened) {
      this.startPopup.show();
    } else {
      this.mainPopup.show(this._betAmount, this._winAmount);
    }
    document.addEventListener('keydown', this._onKeydown, true);
  }

  tryShow() {
    if (this._blockedGame && !this._blockedRealityCheck) this.show();
  }

  hide() {
    this._winAmount = 0;
    this._betAmount = 0;
    document.removeEventListener('keydown', this._onKeydown, true);
    this.view.style.setProperty('visibility', 'hidden');
    this.view.style.setProperty('opacity', '0');
    this.mainPopup.hide();
    this.startPopup.hide();
  }

  onActiveTimeout(timeout) {
    this.timeout = timeout;
    this.mainPopup.setActiveDropdownOption(this.timeout);
    this.startPopup.setActiveDropdownOption(this.timeout);
  }

  onClickContinue() {
    window.OPWrapperService.initConfig.onButtonClick();
    this.hide();
    this._blockedGame = false;
    setTimeout(()=> {
      this._blockedGame = true;
      this.tryShow();
    }, this.timeout * 60 * 1000)
  }

  onExitClick() {
    window.OPWrapperService.initConfig.onButtonClick();
    const homeURL = getUrlParam('lobbyUrl');
    if (homeURL) {
      window.top.location.href = homeURL;
    } else if (window.history.length > 1) {
      history.back();
    } else {
      this._showBlocker()
    }
  }

  _showBlocker() {

  }

  _onKeydown(e) {
    if (e.keyCode !== 32) return;
    e.stopPropagation();
  }
}
