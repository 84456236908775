import LocalizationManager from '../i18n/LocalizationManager';
import RealityCheckDropdown from './components/realityCheckDropdown';

export default class MainRealityCheckPopup {
  constructor(container, timeouts, partnerConfig,  onActiveTimeout, onClickContinue, onExitClick) {
    this.container = container;
    this.timeouts = timeouts;
    this.partnerConfig = partnerConfig;
    this.onActiveTimeout = onActiveTimeout;
    this.onClickContinue = onClickContinue
    this.onExitClick = onExitClick
    this.updateGameTime = this.updateGameTime.bind(this);

    this.init()
  }

  init() {
    this.dropdown = new RealityCheckDropdown(this.timeouts, this.onActiveTimeout);
    this._createMarkup()
    this.view = this.container.querySelector('#mainRealityCheckPopup');
    this.dropdown.init(this.view)
    this.title = this.view.querySelector('#realityCheckTitle');
    this.stopwatch = this.view.querySelector('#realityCheck__stopwach');
    this.winAmountView = this.view.querySelector('#realityCheckWinAmount');
    this.betAmountView = this.view.querySelector('#realityCheckBetAmount');
    this.continueButton = this.view.querySelector('.realityCheck__button_continue');
    this.exitButton = this.view.querySelector('.realityCheck__button_closeGame');
    this.continueButton.addEventListener('click', this.onClickContinue);
    this.exitButton.addEventListener('click', this.onExitClick);
  }

  setActiveDropdownOption(timeout) {
    this.dropdown.setActiveDropdownOption(timeout)
  }

  updateGameTime({timeDifferenceText}) {
    this.stopwatch.textContent = timeDifferenceText;
  }

  show(betAmount, winAmount) {
    this.winAmountView.textContent = `${winAmount} ${this.partnerConfig.currency}`
    this.betAmountView.textContent = `${betAmount} ${this.partnerConfig.currency}`
    this.view.style.setProperty('visibility', 'visible');
  }

  hide() {
    this.view.style.setProperty('visibility', 'hidden');
  }

  _createMarkup() {
    const markup = `
      <div id="mainRealityCheckPopup">
        <div id="realityCheckContainer">
          <div class="realityCheck__head">
            ${LocalizationManager.getLocalizedText('OPWrapperService.realityCheck.head')} <span id="realityCheck__stopwach"><span>          
            </div>
          <div id="realityCheckContent">
          <div class="realityCheck__title">${LocalizationManager.getLocalizedText('OPWrapperService.realityCheck.title')}</div>
          <div class="realityCheck__desc">
            <span>${LocalizationManager.getLocalizedText('OPWrapperService.realityCheck.desc_0')}</span>
            <span class="realityCheck__descAmount" id="realityCheckWinAmount">0,12345678 BTC</span> 
            <span>${LocalizationManager.getLocalizedText('OPWrapperService.realityCheck.desc_1')}</span>
            <span class="realityCheck__descAmount" id="realityCheckBetAmount">0,12345678 BTC</span> 
            <span>${LocalizationManager.getLocalizedText('OPWrapperService.realityCheck.desc_2')}</span>
          </div>
          ${this.dropdown.createMarkup()}
          <div class="realityCheckButtonsBlock">
            <button class="realityCheck__button realityCheck__button_continue">${LocalizationManager.getLocalizedText('OPWrapperService.realityCheck.continue')}</button>
            <button class="realityCheck__button realityCheck__button_closeGame">${LocalizationManager.getLocalizedText('OPWrapperService.realityCheck.closeGame')}</button>
          </div>
          </div>
        </div>
      </div>`;
    this.container.insertAdjacentHTML('beforeend', markup );
  }
}
